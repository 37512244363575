import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Modal,
  Button as Btn,
  // OnChangeData,
  Stack,
  TextArea,
} from "@carbon/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useLoading from "~/hooks/useLoading";
import { TrashCan, View } from "@carbon/icons-react";
import {
  getAttendAPI,
  getAttendDetailsAPI,
  getAttendHistoryAPI,
  // getAttendHistoryAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import { Tabs } from "antd";
import useAttend from "~/hooks/useAttend";
import { getFirstLetters, timeAgo } from "~/services/utils";
import UpdateStatusAttendModal from "./UpdateStatusAttendModal";
import UpdateAreaAttendModal from "./UpdateAreaAttendModal";
import useAreaType from "~/hooks/useAreaType";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import { IAttendLog, IFile, IHistory } from "~/types";
import UpdateAttendTypeModal from "./UpdateAttendTypeModal";
import { postAttendTaskMessageAPI } from "~/services/api/AttendTaskMessage";

import useUser from "~/hooks/useUser";
// import AttendDetailsTasks from "./AttendDetailsTasks";
import UpdateOwnerAttendModal from "./UpdateOwnerAttendModal";
import { getAttendLogsAPI } from "~/services/api/attendLog";
import LateralDetailsAttends from "./LateralDetailsAttends";
// import Label from "~/components/Label";
import AttendDetailsFiles from "./AttendDetailsFiles";
import SubAttends from "./SubAttends/SubAttends";
import NewSubAttend from "./SubAttends/NewSubAttend";
import History from "./History";
import { Button } from "prosperita-dumbo-react";
import { compareAsc, format } from "date-fns";
import AttendFilesDeleteFileModal from "./AttendFilesDeleteFileModal";
import { useDropzone } from "react-dropzone";
import {
  getAttendTaskFileAPI,
  postAttendTaskFileAPI,
} from "~/services/api/attendTaskFile";
import Label from "~/components/Label";
import { toast } from "react-toastify";

const ContainerFile = styled.div`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid rgba(38, 0, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
`;

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;
    background: #ffffff;
  }

  .form {
    background: #ffffff;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;
interface PreviewFile extends File {
  preview: string;
}
type Values = {
  message: string;
  message_return: string;
  selectedOption: string;
};

const initialValues: Values = {
  message: "",
  message_return: "",
  selectedOption: "",
};

const validationSchema = Yup.object({
  message: Yup.string(),
  selectedOption: Yup.string(),
});

const notifySuccessMessage = () =>
  toast.success("Mensagem enviada com sucesso!");

const notifyErrorMessage = () =>
  toast.error(
    "Não foi possível enviar a mensagem. Tente novamente mais tarde."
  );

const notifySuccessFile = () => toast.success("Arquivo enviada com sucesso!");

const notifyErrorFile = () =>
  toast.error("Não foi possível enviar o arquivo. Tente novamente mais tarde.");

const AttendDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAreaTypes } = useAreaType();
  const {
    attendDetails,
    setAttendDetails,
    openUpdateAttendStatusModal,
    setOpenUpdatedAttendStatusModal,
    setOpenUpdatedAttendAreaModal,
    openUpdateAttendAreaModal,
    openUpdateAreaTypeModal,
    openUpdateOwnerAttendModal,
    setOpenUpdatedOwnerAttendModal,
    setOpenUpdatedAreaTypeModal,
    setAttendLog,
    setNewSubAttend,
    setHistory,
    history,
    newSubAttend,
    setAttendFiles,
    setSubAttends,
    subAttends,
    setOpenDeleteFileModal,
    openDeleteFileModal,
  } = useAttend();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const [fileSendedModalOpen, setFileSendedModalOpen] = useState(false);
  const [selectedFileSended, setSelectedFileSended] = useState<IFile | null>(
    null
  );
  const isSendingRef = useRef(false);

  const closeModalFileSended = async () => {
    await setFileSendedModalOpen(false);
    setSelectedFileSended(null);
  };

  const openModal = (file: PreviewFile) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = async () => {
    await setModalIsOpen(false);
    setSelectedFile(null);
  };

  const { userProfile } = useUser();
  const { TabPane } = Tabs;

  const { setLoading } = useLoading();

  const [openReturnAttendModal, setOpenReturnAttendModal] =
    useState<boolean>(false);
  const [files, setFiles] = useState<PreviewFile[]>([]);

  const { values, handleSubmit, setFieldValue, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        null;
      },
    });

  // interface DropdownItem {
  //   id: string;
  //   label: string;
  // }

  // const items: DropdownItem[] = [
  //   { id: "1", label: "Responder" },
  //   { id: "2", label: "Nota Privada" },
  // ];

  // const handleDropdownChange = (eventData: OnChangeData<DropdownItem>) => {
  //   const selectedItem = eventData.selectedItem;

  //   if (selectedItem) {
  //     setFieldValue("selectedOption", selectedItem.id);
  //   }
  // };

  const isSolicitant = useMemo(() => {
    return attendDetails?.client.id === userProfile?.id;
  }, [attendDetails, userProfile]);

  //depois preciso colocar o isWatcher

  const fetchAttends = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const { data } = await getAttendDetailsAPI(Number(id));

        setAttendDetails(data);

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id]);

  const fetchAttendLog = async () => {
    try {
      const { data } = await getAttendLogsAPI();

      const filteredData = data.results.filter(
        (log: IAttendLog) => log.attend === Number(id)
      );

      setAttendLog(filteredData[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchToClientValidation = useCallback(
    async (boolean: boolean) => {
      try {
        await patchAttendDetailsAPI(Number(id), {
          in_client_revision: boolean,
        });
        fetchAttends();
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [id, fetchAttends]
  );

  const paylodMessage = useMemo(() => {
    return {
      sender: userProfile?.id === attendDetails?.client.id ? "client" : "owner",
      message: openReturnAttendModal ? values.message_return : values.message,
      subject: userProfile?.name,
      attend: attendDetails?.id,
    };
  }, [
    values.message,
    attendDetails?.id,
    userProfile?.name,
    attendDetails?.client.id,
    userProfile?.id,
    openReturnAttendModal,
    values.message_return,
  ]);

  const fetchSendAttendsTaskMessages = useCallback(async () => {
    setLoading(true);
    try {
      await postAttendTaskMessageAPI(paylodMessage);
      setFieldValue(openReturnAttendModal ? "message_return" : "message", "");
      await fetchHistory();
      notifySuccessMessage();
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyErrorMessage();
    }
  }, [
    paylodMessage,
    setFieldValue,
    openReturnAttendModal,
    fetchHistory,
    setLoading,
  ]);

  // const fetchHistory = useCallback(async () => {
  //   try {
  //     await getAttendHistoryAPI(70);
  //   } catch (err: unknown) {
  //     console.error(err);
  //   }
  // }, []);

  const _fetchAttends = useCallback(async () => {
    if (attendDetails) {
      try {
        const { data } = await getAttendDetailsAPI(Number(attendDetails?.id));

        setAttendDetails(data);
      } catch (err: unknown) {
        console.error(err);
      }
    }
  }, [attendDetails, setAttendDetails]);

  const fetchSendNewStatus = useCallback(
    async (id: number) => {
      try {
        await patchAttendDetailsAPI(Number(attendDetails?.id), {
          status: id,
        });
        _fetchAttends();
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [attendDetails, _fetchAttends]
  );

  const sendFile = useCallback(
    async (file: PreviewFile) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("target", String(attendDetails?.owner?.id || ""));
      formData.append("sender", String(userProfile?.id || ""));
      formData.append("attend", String(attendDetails?.id));
      formData.append("description", `${file?.name}`);

      await postAttendTaskFileAPI(formData);
    },
    [attendDetails?.id, attendDetails?.owner?.id, userProfile?.id]
  );

  const sortedHistory = useMemo(() => {
    return history
      .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
      .reverse();
  }, [history]);

  const fetchAttendTaskFiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTaskFileAPI();

      const filteredData = data.results.filter(
        (file: IFile) => file.attend === attendDetails?.id
      );

      setAttendFiles(filteredData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [attendDetails?.id, setAttendFiles, setLoading]);

  const fetchSendNewAttendTaskFile = useCallback(async () => {
    setLoading(true);
    try {
      const filePromises = files.map((file: PreviewFile) => sendFile(file));

      await Promise.all(filePromises);
      setFiles([]);
      await fetchAttendTaskFiles();
      notifySuccessFile();
      setLoading(false);
      fetchHistory();
    } catch (e) {
      console.log(e);
      notifyErrorFile();
      setLoading(false);
    }
  }, [fetchAttendTaskFiles, setLoading, files, sendFile, fetchHistory]);

  const fetchSubAttends = useCallback(async () => {
    try {
      const { data } = await getAttendAPI();
      const filteredData = data.results.filter(
        (attend) => attend.parent === Number(attendDetails?.id)
      );

      setSubAttends(filteredData.reverse().sort((a, b) => b.id - a.id));
    } catch (err: unknown) {
      console.error(err);
    }
  }, [attendDetails?.id, setSubAttends]);

  const fetchSendNewStatusSubAttends = useCallback(
    async (id: number) => {
      try {
        await Promise.all(
          subAttends.map(async (subAttend) => {
            await patchAttendDetailsAPI(Number(subAttend.id), {
              status: id,
            });
          })
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [subAttends]
  );

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as PreviewFile[];
      setFiles((prevFiles) => [...prevFiles, ...previewFiles]);
    },
    disabled:
      attendDetails?.status.name === "Cancelado" ||
      attendDetails?.status.name === "Finalizado" ||
      attendDetails?.in_client_revision,
  });

  const handleClick = (item: IHistory) => {
    window.open(item?.actual_info.file, "_blank");
  };

  const allCalleds = async () => {
    await fetchAttends();
    fetchSubAttends();
    await fetchAreaTypes();
    await fetchAttendLog();
  };

  useEffect(() => {
    allCalleds();
    setNewSubAttend(false);
    setOpenUpdatedOwnerAttendModal(false);
    return () => {
      setAttendDetails(undefined);
      setAttendLog(undefined);
    };
  }, []);

  useEffect(() => {
    if (
      userProfile?.id === attendDetails?.owner?.id &&
      attendDetails?.status.id === 2
    ) {
      fetchSendNewStatus(3);
    }
  }, [userProfile, attendDetails, fetchSendNewStatus]);

  useEffect(() => {
    fetchAttendTaskFiles();
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  return (
    <div>
      {attendDetails !== undefined && (
        <TicketFormContainer>
          <form onSubmit={handleSubmit} className="form">
            <Grid>
              <Column span={16}>
                <Padding y={24}>
                  <Margin mt={30} />
                  <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate("/app/attends/")}>
                      Atendimentos
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      onClick={() => navigate(`/app/attends/${id}`)}
                    >
                      Detalhes
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Padding>
                <Margin mt={-10} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1>
                    #{attendDetails?.id} - {attendDetails?.type?.name}
                  </h1>
                  <div style={{ display: "flex" }}>
                    <div></div>
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  tabBarStyle={{
                    margin: 0,
                    borderBottom: "none",
                    color: "red",
                  }}
                >
                  <TabPane tab="Atividade" key="1">
                    <Grid
                      id="container-center"
                      className="form"
                      style={{ background: "white" }}
                    >
                      <Column span={12}>
                        <div
                          style={{
                            padding: "20px 20px",

                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Stack gap={5}>
                            <Margin mb={-20} />
                            {isSolicitant &&
                              attendDetails?.in_client_revision && (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div>
                                    <h5>
                                      Seu atendimento foi concluído. Podemos
                                      finalizar a solicitação?
                                    </h5>

                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        gap: "20px",
                                      }}
                                    >
                                      <Button
                                        kind="dangerTertiary"
                                        size="large"
                                        onClick={() => {
                                          setOpenReturnAttendModal(true);
                                        }}
                                        label="Reabrir solicitação"
                                      />
                                      <Button
                                        kind="primary"
                                        size="large"
                                        onClick={async () => {
                                          setLoading(true);
                                          await fetchSendNewStatus(4);
                                          await fetchToClientValidation(false);
                                          await fetchSendNewStatusSubAttends(4);
                                          setLoading(false);
                                        }}
                                        label="Finalizar solicitação"
                                      />
                                    </div>
                                    <Margin mb={20} />
                                  </div>
                                </div>
                              )}
                            <div>
                              <TextArea
                                id="message"
                                name="message"
                                labelText={`Mensagem - ${values.message.length}/1000 caracteres`}
                                required
                                maxLength={1000}
                                disabled={
                                  (userProfile?.id !==
                                    attendDetails?.client?.id &&
                                    userProfile?.id !==
                                      attendDetails?.owner?.id) ||
                                  attendDetails.status.name === "Cancelado" ||
                                  attendDetails.status.name === "Finalizado" ||
                                  attendDetails.in_client_revision
                                }
                                rows={6}
                                placeholder="Digite a mensagem"
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{ resize: "none" }}
                              />
                            </div>
                          </Stack>
                          <Margin mb={30} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            {/* {!isSolicitant && (
                              <div>
                                <Margin mt={-20} />
                                <div style={{ width: "300px" }}>
                                  <Label text="Enviar mensagem como" />
                                  <Dropdown
                                    id="example-dropdown"
                                    label="Selecione o modo de envio"
                                    items={items}
                                    itemToString={(item) =>
                                      item ? item.label : ""
                                    }
                                    onChange={(selectedItem) =>
                                      handleDropdownChange(selectedItem)
                                    }
                                  />
                                </div>
                              </div>
                            )} */}
                            <Margin ml={20} />
                            <div style={{ display: "flex", gap: "10px" }}>
                              {/* <div
                                style={{ position: "relative", top: "-17px" }}
                              >
                                <Dropdown
                                  kind="default"
                                  size="large"
                                  label="Escolha a forma de envio"
                                  placeholder="Selecionar"
                                  options={[
                                    { label: "Apenas responder", value: "" },
                                    {
                                      label: "Responder e Finalizar",
                                      value: "",
                                    },
                                  ]}
                                />
                              </div> */}
                              {!isSolicitant && (
                                <div>
                                  <Button
                                    kind="secondary"
                                    size="large"
                                    disabled={
                                      isSendingRef.current ||
                                      (userProfile?.id !==
                                        attendDetails?.client?.id &&
                                        userProfile?.id !==
                                          attendDetails?.owner?.id) ||
                                      attendDetails?.status?.name ===
                                        "Cancelado" ||
                                      attendDetails?.status?.name ===
                                        "Finalizado" ||
                                      values.message === ""
                                    }
                                    onClick={async () => {
                                      if (!isSendingRef.current) {
                                        isSendingRef.current = true;

                                        await fetchSendAttendsTaskMessages();
                                        await fetchToClientValidation(true);
                                        await fetchSendNewStatus(8);

                                        isSendingRef.current = false;
                                      }
                                    }}
                                    label="Enviar e finalizar atendimento"
                                  />
                                </div>
                              )}

                              <div>
                                <Button
                                  kind="primary"
                                  size="large"
                                  disabled={
                                    isSendingRef.current ||
                                    (userProfile?.id !==
                                      attendDetails?.client?.id &&
                                      userProfile?.id !==
                                        attendDetails?.owner?.id) ||
                                    attendDetails?.status?.name ===
                                      "Cancelado" ||
                                    attendDetails?.status?.name ===
                                      "Finalizado" ||
                                    values.message === ""
                                  }
                                  onClick={async () => {
                                    if (!isSendingRef.current) {
                                      isSendingRef.current = true;

                                      await fetchSendAttendsTaskMessages();
                                      if (!isSolicitant) {
                                        await fetchSendNewStatus(8);
                                      } else if (
                                        isSolicitant &&
                                        attendDetails.owner !== null
                                      ) {
                                        await fetchSendNewStatus(3);
                                      }

                                      isSendingRef.current = false;
                                    }
                                  }}
                                  label="Enviar"
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <Margin mb={10} />
                            <h5>Anexos</h5>
                            <div style={{ width: "100%" }}>
                              <div>
                                <Label text="Selecione seus anexos para enviar" />
                                <div
                                  {...getRootProps()}
                                  style={{
                                    border: "2px dashed #cccccc",
                                    padding: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <input {...getInputProps()} />

                                  <p>
                                    Arraste e solte alguns arquivos aqui, ou
                                    clique para selecionar os arquivos
                                  </p>
                                </div>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                {files.map((file, index) => (
                                  <ContainerFile key={String(index)}>
                                    <p>{file.name}</p>
                                    <div
                                      className="d-flex"
                                      style={{ display: "flex" }}
                                    >
                                      <Btn
                                        hasIconOnly
                                        renderIcon={View}
                                        iconDescription="Visualizar"
                                        size="sm"
                                        kind="ghost"
                                        onClick={() => openModal(file)}
                                      />
                                      {/* <Margin ml={10} />
                            <Button
                              hasIconOnly
                              renderIcon={Download}
                              iconDescription="Download"
                              size="sm"
                              kind="ghost"
                              onClick={() =>
                                downloadFile(file.file, file.description)
                              }
                            /> */}
                                      <Margin ml={10} />
                                      <Btn
                                        hasIconOnly
                                        renderIcon={TrashCan}
                                        iconDescription="Excluir"
                                        size="sm"
                                        kind="ghost"
                                        onClick={() => {
                                          handleDelete(file);
                                        }}
                                      />
                                    </div>
                                  </ContainerFile>
                                ))}

                                <Modal
                                  open={modalIsOpen}
                                  onRequestClose={closeModal}
                                  primaryButtonText="Fechar visualização"
                                  onRequestSubmit={closeModal}
                                >
                                  <>
                                    {selectedFile && (
                                      <div>
                                        <h2>{selectedFile.name}</h2>
                                        {selectedFile.type.startsWith(
                                          "image/"
                                        ) ? (
                                          <img
                                            src={selectedFile.preview}
                                            alt={selectedFile.name}
                                            style={{ width: "100%" }}
                                          />
                                        ) : selectedFile.type ===
                                          "application/pdf" ? (
                                          <iframe
                                            src={selectedFile.preview}
                                            title={`Preview of ${selectedFile.name}`}
                                            style={{
                                              width: "100%",
                                              height: "500px",
                                            }}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              height: "400px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h4>
                                              Tipo de arquivo não suportado para
                                              visualização.{" "}
                                              <a
                                                href={selectedFile.preview}
                                                download={selectedFile.name}
                                              >
                                                Clique aqui para baixar
                                              </a>
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                </Modal>

                                <Modal
                                  open={fileSendedModalOpen}
                                  onRequestClose={closeModalFileSended}
                                  primaryButtonText="Salvar documento"
                                  secondaryButtonText="Fechar"
                                  onSecondarySubmit={() =>
                                    closeModalFileSended()
                                  }
                                  onRequestSubmit={() =>
                                    window.open(
                                      selectedFileSended?.file,
                                      "_blank"
                                    )
                                  }
                                >
                                  <>
                                    {selectedFileSended && (
                                      <div>
                                        <h2>
                                          {selectedFileSended.description}
                                        </h2>
                                        {/(png|jpe?g|webp|gif)$/i.test(
                                          selectedFileSended.description
                                        ) ? (
                                          <img
                                            src={selectedFileSended.file}
                                            alt={selectedFileSended.description}
                                            style={{ width: "100%" }}
                                          />
                                        ) : selectedFileSended.description.includes(
                                            "pdf"
                                          ) ? (
                                          <iframe
                                            src={selectedFileSended.file}
                                            title={`Preview of ${selectedFileSended.description}`}
                                            style={{
                                              width: "100%",
                                              height: "500px",
                                            }}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              height: "400px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h4>
                                              Tipo de arquivo não suportado para
                                              visualização.{" "}
                                              <a
                                                href={selectedFileSended.file}
                                                download={
                                                  selectedFileSended.description
                                                }
                                              >
                                                Clique aqui para baixar
                                              </a>
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                </Modal>

                                <Margin mb={10} />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <Button
                                    size="large"
                                    onClick={async () => {
                                      if (!isSendingRef.current) {
                                        isSendingRef.current = true;

                                        await fetchSendNewAttendTaskFile();

                                        isSendingRef.current = false;
                                      }
                                    }}
                                    kind="primary"
                                    disabled={files?.length === 0}
                                    label={
                                      files?.length > 1
                                        ? "Enviar arquivos"
                                        : "Enviar arquivo"
                                    }
                                  />
                                </div>
                              </div>
                              <Margin mb={40} />
                            </div>

                            <AttendFilesDeleteFileModal
                              open={openDeleteFileModal}
                              onClose={() => setOpenDeleteFileModal(false)}
                            />
                          </div>

                          {/* {messages.length > 0 &&
                            messages
                              .filter(
                                (message) =>
                                  !(
                                    isSolicitant &&
                                    message.sender === "internal"
                                  )
                              )
                              .map((message, index) => {
                                return (
                                  <div
                                    key={String(index)}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                      paddingTop: "40px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          background:
                                            message.sender === "internal"
                                              ? "#85b0ff"
                                              : "#b8b8b8",
                                          borderRadius: "50%",
                                          width: "50px",
                                          height: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {getFirstLetters(message.subject)}
                                        </h3>
                                      </div>
                                      <Margin ml={20} />
                                      <div>
                                        <h4
                                          style={{
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {message.sender === "internal"
                                            ? `${message.subject} - Nota Privada`
                                            : message.subject}
                                        </h4>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {timeAgo(message.created_on)}
                                        </p>
                                        <Margin mb={30} />
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        marginLeft: "10px",
                                        marginBottom: "60px",
                                        color:
                                          message.sender === "internal"
                                            ? "#0f62fe"
                                            : "",
                                      }}
                                    >
                                      {message.message}
                                    </p>
                                  </div>
                                );
                              })} */}

                          {sortedHistory?.length > 0 &&
                            sortedHistory
                              ?.filter(
                                (history) =>
                                  (history.type === "AttendTaskMessage" ||
                                    history.type === "AttendTaskFile") &&
                                  history.actual_info.attend ===
                                    attendDetails?.id
                              )
                              ?.map((item, index) => (
                                <Fragment key={index}>
                                  <div
                                    style={{
                                      padding: "40px 20px",
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    {item.type === "AttendTaskMessage" && (
                                      <div>
                                        <div style={{ display: "flex" }}>
                                          <div
                                            style={{
                                              background: "#b8b8b8",
                                              borderRadius: "50%",
                                              width: "50px",
                                              height: "50px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <h3>
                                              {item.actual_info.subject &&
                                                getFirstLetters(
                                                  item.actual_info.subject
                                                )}
                                            </h3>
                                          </div>
                                          <Margin ml={20} />
                                          <div>
                                            <h4 style={{ fontWeight: "500" }}>
                                              {item.actual_info.subject}
                                            </h4>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                color: "#525252",
                                              }}
                                            >
                                              {format(
                                                new Date(item.date),
                                                "dd/MM/yyyy - HH:mm"
                                              )}
                                            </p>
                                            <Margin mb={30} />
                                          </div>
                                        </div>
                                        <p style={{ marginLeft: "10px" }}>
                                          {item.actual_info.message}
                                        </p>
                                      </div>
                                    )}

                                    {item.type === "AttendTaskFile" && (
                                      <div>
                                        <p style={{ display: "flex" }}>
                                          {` Arquivo enviado por ${item.trigger} `}{" "}
                                          <span style={{ color: "#525252" }}>
                                            {" "}
                                            -{" "}
                                            {format(
                                              new Date(item.date),
                                              "dd/MM/yyyy - HH:mm"
                                            )}
                                          </span>
                                        </p>
                                        <Margin mb={10} />
                                        <button
                                          style={{
                                            color: "blue ",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClick(item)}
                                        >
                                          {item?.actual_info.description}
                                          FUDD
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </Fragment>
                              ))}

                          <Margin mt={60} />
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                background: "#b8b8b8",
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <h3>
                                {getFirstLetters(attendDetails?.client.name)}
                              </h3>
                            </div>
                            <Margin ml={20} />
                            <div>
                              <h4>{attendDetails.client.name}</h4>
                              <p style={{ fontSize: "12px" }}>
                                {timeAgo(attendDetails?.created_on)}
                              </p>
                              <Margin mb={30} />
                            </div>
                          </div>
                          <p style={{ marginLeft: "10px" }}>
                            {attendDetails.message}
                          </p>
                        </div>
                        <Margin mb={100} />
                        <Grid></Grid>
                      </Column>
                      <LateralDetailsAttends />
                    </Grid>
                  </TabPane>
                  <TabPane tab="Anexos" key="2">
                    <Grid
                      id="container-center"
                      className="form"
                      style={{ background: "white" }}
                    >
                      <Column span={12}>
                        <AttendDetailsFiles />
                      </Column>
                      <LateralDetailsAttends />
                    </Grid>
                  </TabPane>
                  {/* {!isSolicitant && (
                    <TabPane tab="Tarefas" key="3">
                      <Grid
                        id="container-center"
                        className="form"
                        style={{ background: "white" }}
                      >
                        <Column span={12}>
                          <AttendDetailsTasks />
                        </Column>
                        <LateralDetailsAttends />
                      </Grid>
                    </TabPane>
                  )} */}

                  {(attendDetails?.client?.type?.includes("internal") ||
                    attendDetails?.client?.type?.includes("area_manager") ||
                    attendDetails?.client?.type?.includes("tool_manager")) &&
                    !isSolicitant && (
                      <TabPane tab="Chamados" key="3">
                        <Grid
                          id="container-center"
                          className="form"
                          style={{ background: "white" }}
                        >
                          <Column span={12}>
                            {newSubAttend ? <NewSubAttend /> : <SubAttends />}
                          </Column>
                          <LateralDetailsAttends />
                        </Grid>
                      </TabPane>
                    )}
                  {userProfile?.type !== "manager" &&
                    userProfile?.type !== "client" &&
                    !isSolicitant && (
                      <TabPane tab="Histórico" key="4">
                        <Grid
                          id="container-center"
                          className="form"
                          style={{ background: "white" }}
                        >
                          <Column span={12}>
                            <History />
                          </Column>
                          <LateralDetailsAttends />
                        </Grid>
                      </TabPane>
                    )}
                </Tabs>
              </Column>
            </Grid>
          </form>
          <UpdateStatusAttendModal
            open={openUpdateAttendStatusModal}
            onClose={() => setOpenUpdatedAttendStatusModal(false)}
          />
          <UpdateAreaAttendModal
            open={openUpdateAttendAreaModal}
            onClose={() => setOpenUpdatedAttendAreaModal(false)}
          />
          <UpdateAttendTypeModal
            open={openUpdateAreaTypeModal}
            onClose={() => setOpenUpdatedAreaTypeModal(false)}
          />
          <UpdateOwnerAttendModal
            open={openUpdateOwnerAttendModal}
            onClose={() => setOpenUpdatedOwnerAttendModal(false)}
          />
        </TicketFormContainer>
      )}
      <Modal
        modalHeading={`Reabrir solicitação - ${attendDetails?.type.name}`}
        size="sm"
        open={openReturnAttendModal}
        onRequestClose={() => setOpenReturnAttendModal(false)}
        primaryButtonText="Continuar"
        primaryButtonDisabled={values.message_return === ""}
        secondaryButtonText="Voltar"
        onRequestSubmit={async () => {
          setLoading(true);
          await fetchSendAttendsTaskMessages();

          await fetchSendNewStatus(3);
          await fetchToClientValidation(false);
          setOpenReturnAttendModal(false);
          setLoading(false);
        }}
        style={{ zIndex: 2 }}
      >
        <div>
          <Margin mb={50} />
          <h4>
            {" "}
            Por favor, informe o motivo de estar reabrindo esta solicitação
          </h4>
          <Margin mb={20} />
          <TextArea
            id="message_return"
            name="message_return"
            labelText="Mensagem"
            required
            rows={6}
            placeholder="Digite a mensagem"
            value={values.message_return}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ resize: "none" }}
          />

          <Margin mb={150} />
        </div>
      </Modal>
    </div>
  );
};

export default AttendDetails;
