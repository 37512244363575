import { useFormik } from "formik";
import { Checkbox, InputFluid, Modal, Radio } from "prosperita-dumbo-react";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import useAttend from "~/hooks/useAttend";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import useUser from "~/hooks/useUser";
import { getAttendHistoryAPI } from "~/services/api/attend";
import { postAttendTaskMessageAPI } from "~/services/api/AttendTaskMessage";
import { Forum, getForumFilteredAPI, getForumVoteUser, getTypeUserRole, pacthForumFilteredAPI, postForumVoteUser } from "~/services/api/forum";

export type IroleForumType = {
    created_on: string;
    deleted: string | null;
    deleted_by_cascade: boolean;
    forum_type: number;
    id: number;
    type_vote: string;
    updated_on: string;
    user: { id: number, first_name: string, last_name?: string };
    user_status: string;
    vote_decision: string;
    vote_status: string;
}

type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum?: Forum | null,
    listRole?: IroleForumType[]
}
type InitialValues = {
    forum: string,
    text_vote: string,
    checkbox: boolean
}

export const ModalVoteUser = ({ open, onClose, forum, listRole }: ItypeModal) => {

    const location = useLocation();

    const { id } = location.state || {};

    const { setLoading } = useLoading()

    const { setNotification, closeNotification } = useNotification()

    const { userProfile } = useUser();

    // const allConditionsMet = listRole?.every(
    //     (list) => list.vote_status === "voto_realizado" && list.type_vote === "vota"
    // );

    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const [checkedOption, setcheckedOption] = useState<string | null>(null);

    const {
        setHistory,
    } = useAttend();

    const handleChangeRadio = (value: string) => {
        setSelectedOption(value);
    };

    const {
        participantList,
        listForumFiltered,
        setParticipantList,
        listForumVoteUser,
        setlistForumVoteUser,
        setListForumlistForumFiltered,
        setModalVoteUser
    } = useForum()

    const fetchForumAPI = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await getForumFilteredAPI(id)

            setListForumlistForumFiltered(data)

        } catch {

        } finally {

            setLoading(false)
        }

    }, [listForumFiltered])

    const fetchParticipantMandatoryForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeUserRole(listForumFiltered?.forum_type?.id)

            setParticipantList(data.results)

        } catch {

            setParticipantList([])

        } finally {

            setLoading(false);
        }

    }, [participantList, listForumFiltered])


    const fetchForumVote = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getForumVoteUser(id)

            setlistForumVoteUser(data.results)

        } catch {

        } finally {

            setLoading(false)
        }
    }, [listForumVoteUser, id])

    const { values, setFieldValue, resetForm, handleChange } = useFormik({

        initialValues: {
            forum: "",
            checkbox: false,
            text_vote: "",
        } as InitialValues,
        onSubmit: () => { }
    })

    const fetchHistory = useCallback(async () => {
        try {
            const { data } = await getAttendHistoryAPI(Number(listForumFiltered?.attend));
            setHistory(data);
        } catch (err: unknown) {
            console.error(err);
        }
    }, [listForumFiltered]);

    const userTypeVote = listRole?.find(
        (participant) => participant.user.id === Number(userProfile?.user.id)
    )?.type_vote;

    const fetchForumUserVote = useCallback(async () => {

        setLoading(true)

        try {

            await postForumVoteUser({
                forum: Number(forum?.id),
                forum_type: Number(forum?.forum_type.id),
                user: Number(userProfile?.user.id),
                vote_decision: values.forum,
                vote_status: "voto_realizado"
            })

            await postAttendTaskMessageAPI({
                sender: "client",
                message: `Votou ${values?.text_vote}`,
                subject: String(userProfile?.id),
                attend: listForumFiltered?.attend,
            })

            await fetchHistory()
            
            if (userTypeVote === "voto_com_veto" && values.forum === "vetado") {

                await pacthForumFilteredAPI(Number(forum?.id), {
                    forum_type: String(listForumFiltered?.forum_type.id),
                    forum_status: "vetado",
                    name: listForumFiltered?.name || "",
                    vote_deadline: String(listForumFiltered?.vote_deadline),
                    attend: String(listForumFiltered?.attend),
                });

                await fetchForumAPI();

                return;
            }
            const totalParticipants = listRole?.filter((participant) =>
                participant.type_vote === "vota" || participant.type_vote === "voto_com_veto").length || 0;

            const votesRealized = listRole?.filter((list) =>
                list.vote_status === "voto_realizado").length || 0;

            if (listForumFiltered && votesRealized + 1 === totalParticipants) {

                await pacthForumFilteredAPI(Number(forum?.id), {
                    forum_type: String(listForumFiltered?.forum_type.id),
                    forum_status: "encerrado",
                    name: listForumFiltered?.name,
                    vote_deadline: String(listForumFiltered?.vote_deadline),
                    attend: String(listForumFiltered?.attend),
                })

                await fetchForumAPI()
            }

            setNotification({
                status: "success",
                message: "Seu voto foi computado com sucesso..",
                title: "Voto computado",
                actived: true,
                onClose: () => closeNotification()
            });

            onClose && onClose()

        } catch {
            setNotification({
                status: "error",
                message: "Não foi possível computar seu voto, tente novamente mais tarde ou contate o administrador do sistema.",
                title: "Falha ao computar voto",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false)
            fetchForumVote()
            fetchForumAPI()
            fetchParticipantMandatoryForum()
            setModalVoteUser(false)
        }

    }, [forum, values, userProfile, id, listForumFiltered, userTypeVote])


    const isValid = () => {
        const { checkbox, forum, text_vote } = values;
        return checkbox && forum.trim() !== "" && (text_vote?.trim() || "").trim() !== "";
    };

    // useEffect(() => {
    //     pacthForumFilteredAPI(Number(forum?.id), {
    //         forum_type: String(listForumFiltered?.forum_type.id),
    //         forum_status: "encerrado",
    //         name: String(listForumFiltered?.name),
    //         vote_deadline: String(listForumFiltered?.vote_deadline),
    //         attend: String(listForumFiltered?.attend),
    //     })
    // }, [])


    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Confirmar voto</h3>}
            open={open}
            height={"430px"}
            width="45%"
            onClose={() => {
                onClose && onClose();
                resetForm()
                setSelectedOption(null);
                setcheckedOption(null);
            }}
            background="#ffffff"
            buttons={[
                {
                    label: "Cancelar",
                    kind: "ghost",
                    onClick: () => {
                        onClose && onClose();
                        resetForm()
                        setSelectedOption(null);
                        setcheckedOption(null);
                    },
                },
                {
                    label: "Votar",
                    kind: "primary",
                    onClick: () => fetchForumUserVote(),
                    disabled: !isValid()
                },
            ]}
        >
            <div className="w-[95%] mx-auto">
                <p className="w-[90%] flex justify-start">Selecionar voto</p>
                <div className="flex mx-auto w-[100%]">
                    <div className="w-[100px]">
                        <Radio
                            label="Aprovar"
                            name="forum"
                            checked={selectedOption === "aprovado"}
                            onChange={() => {
                                setFieldValue("forum", "aprovado")
                                handleChangeRadio("aprovado")
                            }}
                        />
                    </div>
                    <div className="w-[100px]">
                        <Radio
                            label="Rejeitar"
                            name="forum"
                            checked={selectedOption === "reprovado"}
                            onChange={() => {
                                setFieldValue("forum", "reprovado")
                                handleChangeRadio("reprovado")
                            }}
                        />
                    </div>
                    <div className="w-[130px]">
                        <Radio
                            label="Condicionado"
                            name="forum"
                            checked={selectedOption === "pendente_de_votacao"}
                            onChange={() => {
                                setFieldValue("forum", "pendente_de_votacao")
                                handleChangeRadio("pendente_de_votacao")

                            }}
                        />
                    </div>
                    {userTypeVote === "voto_com_veto" &&
                        <div className="w-[100px]">
                            <Radio
                                label="Vetar"
                                name="forum"
                                checked={selectedOption === "vetado"}
                                onChange={() => {
                                    setFieldValue("forum", "vetado")
                                    handleChangeRadio("vetado")
                                }}
                            />
                        </div>}

                </div>
            </div>
            <div className="w-[95%] mx-auto mt-[10px]">
                <InputFluid
                    label="Justificativa obrigatória"
                    type="textarea"
                    name="text_vote"
                    value={values?.text_vote}
                    onChange={handleChange}
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
                    rows={5}
                />
            </div>
            <div className="text-center w-[95%] flex mx-auto mt-[10px]">
                <div className="w-[30px]">
                    <Checkbox
                        checked={checkedOption === "check"}
                        onChange={(event) => {
                            const newChecked = event.target.checked;
                            setFieldValue("checkbox", newChecked); // Atualiza o valor do campo do formulário
                            setcheckedOption(newChecked ? "check" : ""); // Condicionalmente define o valor de checkedOption
                        }}
                    />

                </div>
                <p className="p-0 m-0">Estou ciente que uma vez confirmado, meu voto não poderá ser alterado.? </p>
            </div>
        </Modal>
    )
}
