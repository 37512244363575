import { IActualUser } from "~/types";
import ApiService from "../api";

type IResponseGetUser = {
  count: number;
  next: null;
  previous: null;
  results: IActualUser[];
};

export const getUserAPI = () => {
  return ApiService.HttpGet<IResponseGetUser>({
    route: "user/",
    token: true,
  });
};

export const getUserFilteredAPI = (id: number) => {
  return ApiService.HttpGet<IResponseGetUser>({
    route: `user/${id}`,
    token: true,
  });
};

export const postUserAPI = (body: unknown, id: number) => {
  return ApiService.HttpPatch<unknown>({
    route: `user/${id}/`,
    body,
    token: true,
  });
};
