import { IconSucess, InfoIcon, removeIconToast } from "~/assets/IconsSVG"
import {
    deleteNotificationsInvite,
    getNotificationsInvite,
    pacthNotificationsInvite
} from "~/services/api/notifications";
import { extrairNumeroChat, formatDateTime } from "~/services/utils";
import styled from 'styled-components';
import useNotification from "~/hooks/useToast";
import { useCallback, useEffect } from "react";
import useUser from "~/hooks/useUser";
import { useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum";
import { getForumAPI } from "~/services/api/forum";

const SectionNotification = styled.div`
  position: fixed;
  height: calc(100vh - 157px); // Altura calculada para respeitar o topo e o rodapé
  width: 350px;
  background: #f4f4f4; // Cor de fundo similar
  right: 50px;
  top: 117px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Sombra leve
  // Rolagem caso o conteúdo ultrapasse o limite
  color: #ffffff; // Texto branco
  padding: 1px; // Espaçamento interno
  margin: 0 auto;
  z-index: 20;

  overflow-y: scroll; /* Forçando a rolagem vertical */
  
  /* Personalizando o scrollbar */
  ::-webkit-scrollbar {
    width: 1000px; /* Largura do scrollbar */
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Cor de fundo do track */
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do thumb */
    border-radius: 6px;
    border: 3px solid #f1f1f1; /* Borda para dar destaque */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Cor do thumb ao passar o mouse */
  }
`;

const SmallText = styled.p`
  color: #5a5858ec;
  font-size: 16px;
  font-weight: 500; 
  font-family: "IBM Plex Sans";
  padding: 0;
  margin: 0;
`;

const MediumHeading = styled.h4`
  color: black;
  font-size: 18px;
  font-weight: 600; 
  font-family: "IBM Plex Sans";
  padding: 0;
  margin: 5px 0;
`;

const RegularText = styled.p`
  color: #646363;
  font-size: 16px;
  font-weight: 600; 
  font-family: "IBM Plex Sans";
  padding: 0;
  margin: 0;
`;

const XIcon = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #646363; 
  border-radius: 100px; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #3c3f41;
    padding: 2px;
    color: #ffffff;
  }
`;

export const SideNotification = () => {

    const {
        setInviteNotification,
        inviteNotification,
        setToast,
        setNotification,
        closeNotification
    } = useNotification()

    const {
        userProfile,
    } = useUser();


    const {
        setListForum,
        listForum,
    } = useForum()

    const navigate = useNavigate()

    const fetchNotificatonFiltered = useCallback(async () => {

        try {
            const { data } = await getNotificationsInvite(Number(userProfile?.user?.id));

            setInviteNotification(data.results)

        } catch (e) {

            console.error(e);
        }
    }, [userProfile, inviteNotification])

    const fetchForumAPI = useCallback(async () => {

        try {
            const { data } = await getForumAPI()

            setListForum(data.results)

        } catch {

        } finally {
        }
    }, [listForum])


    useEffect(() => {
        fetchForumAPI()
    }, [])


    return (
        <SectionNotification>
            <div>
                <div className="flex bg-gray-200 justify-between items-center w-[100%] mx-auto mb-[20px] p-2 border border-gray-300 shadow-md ">
                    <p className="text-[15px] font-[600] text-black m-[0] p-[0]">Não Visualizadas</p>
                    <button
                        className="text-blue-500 text-sm hover:underline p-0 m-0"
                        onClick={async () => {
                            if (!inviteNotification) return;
                            const uploadPromises = inviteNotification
                                .filter((toast) => toast.seen_at === null)
                                .map((toast) => pacthNotificationsInvite(toast.id));
                            await Promise.all(uploadPromises);
                            fetchNotificatonFiltered();
                        }}
                    >Marcar todas como Lidas
                    </button>
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className="mb-[10px]">
                    {inviteNotification && inviteNotification
                        .filter((toast) => toast.seen_at === null)
                        .map((toast) => (
                            <div
                                onClick={async () => {

                                    await pacthNotificationsInvite(toast.id)

                                    fetchNotificatonFiltered()

                                    const forumId = extrairNumeroChat(toast?.subject);

                                    const forumExists = listForum?.some((forum) => Number(forum.id) === Number(forumId));

                                    if (forumExists) {

                                        navigate("/app/forum/activities/",
                                            { state: { id: extrairNumeroChat(toast?.subject) } })

                                    } else {
                                        setNotification({
                                            status: "error",
                                            message: "Este fórum não existe mais.",
                                            title: "Erro",
                                            actived: true,
                                            onClose: () => closeNotification(),
                                        });

                                    }

                                    setToast(false)
                                }}>
                                <div
                                    className="p-[15px] flex justify-between rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300"
                                    style={{
                                        borderLeft: "5px solid #4589FF",
                                        borderBottom: "1px solid #c6c6c6",
                                        borderTop: "1px solid #c6c6c6",
                                        margin: "5px"
                                    }}>
                                    <div className="m-0 p-0">
                                        <SmallText>{formatDateTime(String(toast?.sent_at))}</SmallText>
                                        <MediumHeading>{toast?.subject}</MediumHeading>
                                        <RegularText>{toast?.body}</RegularText>
                                    </div>
                                    <section className="flex flex-col justify-between items-center">
                                        <RegularText>{InfoIcon}</RegularText>
                                    </section>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="flex bg-gray-200 justify-between items-center w-[100%] mx-auto mb-[20px] p-2 border border-gray-300 shadow-md ">
                    <p className="text-[15px] font-[600] text-black m-[0] p-[0]">Visualizadas</p>
                </div>

                {inviteNotification && inviteNotification
                    .filter((toast) => toast.seen_at !== null)
                    .map((toast) => (
                        <div
                            className="p-0 m-[5px]"
                            onClick={async (event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                fetchNotificatonFiltered()

                                const forumId = extrairNumeroChat(toast?.subject);

                                const forumExists = listForum?.some((forum) => Number(forum.id) === Number(forumId));

                                if (forumExists) {
                                    navigate("/app/forum/activities/",
                                        { state: { id: extrairNumeroChat(toast?.subject) } })
                                } else {
                                    setNotification({
                                        status: "error",
                                        message: "Este fórum não existe mais.",
                                        title: "Erro",
                                        actived: true,
                                        onClose: () => closeNotification(),
                                    });

                                }

                                setToast(false)
                            }}>
                            <div
                                className="p-[15px] flex justify-between rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300"
                                // className=" flex justify-between"
                                style={{
                                    borderLeft: "5px solid green",
                                    borderBottom: "1px solid #c6c6c6",
                                    borderTop: "1px solid #c6c6c6",
                                    cursor: "pointer",
                                    margin: "5px"
                                }}>
                                <div>
                                    <SmallText>{formatDateTime(String(toast?.sent_at))}</SmallText>
                                    <MediumHeading>{toast?.subject}</MediumHeading>
                                    <RegularText>{toast?.body}</RegularText>
                                </div>
                                <section className="flex flex-col justify-between items-center">
                                    <XIcon onClick={
                                        async (event) => {
                                            event.stopPropagation()
                                            event.preventDefault()
                                            await deleteNotificationsInvite(toast.id)
                                            fetchNotificatonFiltered()
                                            if (inviteNotification.length === 1) {
                                                setToast(false);
                                            }
                                        }}>{removeIconToast}</XIcon>
                                    <RegularText>{IconSucess}</RegularText>
                                </section>
                            </div>
                        </div>
                    ))}
            </div>
        </SectionNotification>
    )
}
