import { Button, Dropdown, FileUploader, Input } from "prosperita-dumbo-react"
import Margin from "~/components/Margin"
import { ItypeValidate } from "./CreatForumGeneral"
import styled from "styled-components"
import { useCallback, useEffect, useMemo, useState } from "react"
import useForum from "~/hooks/useForum"
import { getTypeForumID } from "~/services/api/forum"
import useUser from "~/hooks/useUser"
import useLoading from "~/hooks/useLoading"
import useFund from "~/hooks/useFund"
import { getFundsAPI } from "~/services/api/fund"
import { IFund } from "~/types"
import { formatCPFCNPJ } from "~/services/utils"

export const TextFirst = styled.p`
color:  #525252;
font-family: "IBM Plex Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.32px;
margin: 0;
padding: 0;
`


export const SettingsForum = ({ onValidate, formik }: ItypeValidate) => {

  const { handleChange, setFieldValue, values } = formik

  const [clearFiles, setClearFiles] = useState<boolean>(false);

  const [listFiles, setListFiles] = useState<unknown[]>([]);

  const { setFunds, funds } = useFund();

  const { setLoading } = useLoading()

  const {
    userProfile,
  } = useUser();

  const {
    setOnlyTypeForum,
    onlyTypeForum,
    setSaveFilesForum,
    saveFilesForum
  } = useForum()

  const handleFilesList = (files: File) => {
    setListFiles((prev) => [...prev, files]);
    setSaveFilesForum((prev) => [...prev, files]);
  };

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);


  const filteredFunds = funds.filter((fund) => fund.raw_data.active === true);

  const fundsOptions = useMemo(() => {
    return filteredFunds
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [filteredFunds]);


  const fetchOnlyTypeForum = useCallback(async () => {

    setLoading(true)

    try {

      const { data } = await getTypeForumID(Number(values.type_forum))

      setOnlyTypeForum(data)

    } catch (error) {

      console.log(error)
    } finally {

      setLoading(false)
    }

  }, [onlyTypeForum, values.type_forum])


  const isValid = () => {
    const { fund, forum_description, title_forum, vote_deadline } = values;
    return onlyTypeForum?.has_fund ?
      fund.trim() !== "" && forum_description.trim() !== "" && title_forum.trim() !== "" && vote_deadline.trim() !== "" :
      forum_description.trim() !== "" && title_forum.trim() !== "" && vote_deadline.trim() !== "";
  };

  onValidate(isValid())

  useEffect(() => {
    fetchOnlyTypeForum()
  }, [values.type_forum])

  useEffect(() => {

    setFieldValue("file", listFiles);

    if (clearFiles) {
      setClearFiles(false);
    }

  }, [listFiles, values, clearFiles]);


  useEffect(() => {
    setFieldValue("forum_description", values.forum_description);
    setFieldValue("title_forum", values.title_forum);
    setFieldValue("vote_deadline", values.vote_deadline);
  }, [])


  console.log(saveFilesForum)
  console.log(values)


  useEffect(() => {
    fetchFunds()
  }, [])

  const getLabelForValue = (value: string) => {
    const option = [
      { label: "12 horas", value: "12_horas" },
      { label: "24 horas", value: "24_horas" },
      { label: "48 horas", value: "48_horas" },
      { label: "72 horas", value: "72_horas" },
    ].find(option => option?.value === value);
    return option ? option?.label : "00 horas";
  };

  const getLabelForValueFunds = (value: string) => {
    const option = fundsOptions.find(option => option?.value === value);
    return option ? option.label : "Escolha uma opção";
  };

  return (
    <div>
      <TextFirst>Solicitante</TextFirst>
      <h5 className="mt-1 p-0 
      text-[18px]">{userProfile?.name}</h5>
      <Margin mt={30} />
      <h6 className="text-[17px]
       font-[600] mb-[10px]">Requisitos</h6>
      <h6 className="text-[14px] leading-[1.42857] 
      tracking-[0.16px] text-[#161616] font-ibm-plex-sans font-normal stroke-black stroke-[1px]">
        {onlyTypeForum?.requirements}</h6>
      <Margin mt={15} />
      <div>
        <h3 className="text-[20px] leading-[1.4] 
        text-black font-ibm-plex-sans font-normal">Configurações do fórum</h3>
        <div>
          <Margin mt={20} />
          <div className="flex gap-[15px] items-center h-[70px]">
            <div className="flex w-[60%] p-0 m-0 gap-[10px]">
              <Input
                autoComplete="off"
                label="Título do fórum (Obrigatório)"
                placeholder="Digite aqui o título do fórum"
                size="medium"
                name={"title_forum"}
                value={values.title_forum}
                onChange={handleChange}
              />
            </div>
            <div
              style={{ transform: "translateY(-5px)", zIndex: "2" }}
              className="flex w-[100%] p-0 m-0 gap-[10px]">
              {onlyTypeForum?.has_fund &&
                <Dropdown
                  autoComplete="off"
                  kind="default"
                  options={fundsOptions}
                  label="Fundo (Obrigatório)"
                  placeholder="Escolha uma opção"
                  size="small"
                  name={"fund"}
                  value={getLabelForValueFunds(values.fund)}
                  onChange={handleChange}
                />
              }
              <Dropdown
                autoComplete="off"
                kind="default"
                options={[
                  { label: "12 horas", value: "12_horas" },
                  { label: "24 horas", value: "24_horas" },
                  { label: "48 horas", value: "48_horas" },
                  { label: "72 horas ", value: "72_horas" },
                ]}
                placeholder="00 horas"
                value={getLabelForValue(values.vote_deadline)}
                label="Prazo final para votação (Obrigatório)"
                size="small"
                name={"vote_deadline"}
                onChange={handleChange}
              />
            </div>
          </div>
          <Margin mt={20} />
          <Input
            autoComplete="off"
            label="Descrição do fórum (Obrigatório)"
            type="textarea"
            placeholder="Descreva um breve resumo o fórum"
            size="large"
            maxLength={500}
            name={"forum_description"}
            value={values?.forum_description}
            onChange={handleChange}
            rows={6}
          />
        </div>
        <Margin mt={26} />
        <div>
          <FileUploader
            size="medium"
            type="drag and top"
            multiple
            noList
            accept=".pdf, .jpg, .png"
            headers={{
              label:
                "Anexar documentos",
              TextLabel:
                " Os tipos de arquivo suportados são .pdf, .jpg, .png."
            }}
            onChange={(e) => {

              const selectedFiles = e.target.files;

              if (selectedFiles) {

                setFieldValue("files", selectedFiles);

                const filesArray = Array.from(selectedFiles)

                filesArray.forEach((file) => {

                  // const convertedFile = {
                  //   name: file.name,
                  //   size: file.size,
                  //   type: file.type,
                  //   lastModifiedDate: new Date(file.lastModified),
                  //   lastModified: file.lastModified,
                  //   webkitRelativePath: file.webkitRelativePath
                  // };

                  handleFilesList(file);

                });
              }
            }}
            maxlenghtindividual={1000}
          >
            <p className="mt-1 p-0">Arraste e solte os arquivos aqui ou clique para fazer upload</p>
          </FileUploader>
          <p className="my-[10px] text-sm text-gray-600">
            Apenas arquivos nos formatos <strong>.pdf</strong>, <strong>.jpg</strong> e <strong>.png</strong> são permitidos.
            O envio de arquivos não compatíveis poderá resultar em erro ou descarte automático.
          </p>
          <div className="my-[30px]">
            {saveFilesForum?.map((item, index) => (
              <div
                key={item?.name}
                className="flex items-center justify-between 
      bg-[#F4F4F4] rounded-[8px] p-3 w-[100%] h-[40px] mb-2"
              >
                <div className="text-[#161616] text-sm">{item?.name}</div>
                <Button
                  label={""}
                  kind="ghost"
                  size="small"
                  hasIconSvg
                  noPadding="2px"
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    setSaveFilesForum((prevFiles) => {
                      return prevFiles.filter((_, i) => i !== index);
                    });
                  }}
                  icon={
                    <svg
                      width="15px"
                      height="15px"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Close">
                        <rect width="16px" height="16px" />
                        <path
                          id="Vector"
                          d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z"
                          fill={"#000000"}
                        />
                      </g>
                    </svg>
                  }
                />
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  )
}
