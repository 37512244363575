import { useFormik } from "formik"
import { Button, FileUploader, Table } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import useForum from "~/hooks/useForum"
import useLoading from "~/hooks/useLoading"
import useUser from "~/hooks/useUser"
import { getAttendTaskFileForumAPI, postAttendTaskFileAPI } from "~/services/api/attendTaskFile"
import { getForumAttachment } from "~/services/api/forum"
import viewIcon from "~/assets/viewIcon.png"
import { formatarDataISO } from "~/services/utils"
import { ModalDocument } from "./ModalDocument"


type Values = {
    file: unknown[]
}

const initialValues: Values = {
    file: []
}
type DocumentsProps = {
    userNotIvite: boolean;
}

export const Documents = ({ userNotIvite }: DocumentsProps) => {

    const {
        listForumFiltered,
        setfilesForumAttend,
        filesForumAttend,
        setModalDocumentData,
        setModalDocument,
        modalDocument
    } = useForum()

    const { setLoading } = useLoading()

    const [listFiles, setListFiles] = useState<unknown[]>([]);

    const { userProfile } = useUser()

    const [clearFiles, setClearFiles] = useState<boolean>(false);

    const { values, setFieldValue } = useFormik({
        onSubmit: () => { },
        initialValues
    })

    const handleFilesList = (files: unknown) => {

        setListFiles((prev) => [...prev, files]);
    };

    const fetchFilesForum = useCallback(async () => {

        setLoading(true);

        try {

            await getForumAttachment(Number(listForumFiltered?.id))

        } catch {

        } finally {

            setLoading(false);
        }

    }, [listForumFiltered])

    const fetchFilesAttendForum = useCallback(async () => {

        try {
            const { data } = await getAttendTaskFileForumAPI(Number(listForumFiltered?.attend))

            setfilesForumAttend(data.results)

        } catch {
        }

    }, [filesForumAttend, listForumFiltered])

    const fetchSendFiles = useCallback(

        async (files: File[]) => {

            setLoading(true);

            // const formData = new FormData();

            // files.forEach((file) => {
            //     if (file instanceof File) {
            //         formData.append("file", file);
            //     }
            // });
            // formData.append("sender", String(userProfile?.id || ""));
            // formData.append("attend", String(listForumFiltered?.attend));
            // formData.append("description", `${listForumFiltered?.description}`);

            try {
                const uploadPromises = files.map((file) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("sender", String(userProfile?.id || ""));
                    formData.append("attend", String(listForumFiltered?.attend));
                    formData.append("description", `${listForumFiltered?.description}`);
                    return postAttendTaskFileAPI(formData);
                });
                // await postAttendTaskFileAPI(formData);
                await Promise.all(uploadPromises);

            } catch (erro) {

                console.warn(erro);

            } finally {

                setLoading(false);

                setClearFiles(true);

                setListFiles([])

                fetchFilesAttendForum()

            }
        },
        [listForumFiltered, userProfile, listFiles, values, clearFiles, setClearFiles]
    );
    function getFileNameFromUrl(url: string) {

        const parsedUrl = new URL(url);

        const pathName = parsedUrl.pathname;

        const fileName = pathName.substring(pathName.lastIndexOf('/') + 1);

        return fileName;
    }

    const listFilesForum = useMemo(() => {

        return filesForumAttend?.map((files) => ({
            "Nome do Arquivo": getFileNameFromUrl(files.file),
            "Última modificação": formatarDataISO(files.updated_on),
            "button":
                <div className="w-[40px]">
                    <Button
                        kind="ghost"
                        size="small"
                        icon={viewIcon}
                        label=""
                        onClick={() => {
                            // window.open(files.file, '_blank')
                            setModalDocument(true)
                            setModalDocumentData(files)
                        }}
                        noPadding="3px"
                    />
                </div>,
        }))
    }, [filesForumAttend])


    useEffect(() => {
        setFieldValue("file", listFiles);
        if (clearFiles) {
            setClearFiles(false);
        }
    }, [listFiles, values, clearFiles]);


    useEffect(() => {
        fetchFilesForum()
        fetchFilesAttendForum()
    }, [])

    return (
        <div>
            <div style={{ borderRadius: "10px" }} className="bg-[#f4f4f4] mx-auto w-[100%] p-[20px]">
                <h4>Descrição</h4>
                <p>{listForumFiltered?.description}</p>
            </div>
            <div className="my-[30px]">
                <FileUploader
                    multiple
                    noList
                    onChange={async (e) => {

                        const selectedFiles = e.target.files;

                        if (selectedFiles) {

                            setFieldValue("files", selectedFiles);

                            const filesArray = Array.from(selectedFiles);

                            filesArray.forEach((file) => {
                                handleFilesList(file);
                            });

                            await fetchSendFiles(filesArray);

                        }
                    }}

                    size="large"
                    headers={{
                        label: "Anexar arquivos",
                        TextLabel: " Os tipos de arquivo suportados são .pdf, .jpg, .png."
                    }}
                    accept=".pdf, .jpg, .png"
                    maxlenghtindividual={1000}
                    type="drag and top"
                    disabled={
                        userNotIvite ||
                        listForumFiltered?.forum_status === "vetado" ||
                        listForumFiltered?.forum_status === "encerrado"
                    }
                >
                    <p className="my-[10px]">Arraste e solte arquivos aqui ou clique para fazer upload</p>
                </FileUploader >
                <p className="my-[10px] text-sm text-gray-600">
                    Apenas arquivos nos formatos <strong>.pdf</strong>, <strong>.jpg</strong> e <strong>.png</strong> são permitidos.
                    O envio de arquivos não compatíveis poderá resultar em erro ou descarte automático.
                </p>
            </div>
            <Table
                columnTitles={["Nome do Arquivo", "Última modificação", ""]}
                rowData={listFilesForum || []}
                radius

            />
            <ModalDocument
                open={modalDocument}
                onClose={() => setModalDocument(false)}
            />
        </div>
    )
}
