import { Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import { deleteTypeUserRoleAPI, getTypeUserRole } from "~/services/api/forum";

type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum_type?: string
}

export const ModalRemoveRoleUser = ({ open, onClose, forum_type }: ItypeModal) => {

    const { setLoading } = useLoading();

    const { setNotification, closeNotification } = useNotification()

    const {
        deleteTypeForumdata,
        onlyParticipant,
        setParticipantList,
        participantList
    } = useForum()

    const location = useLocation();

    const { id_type_forum } = location.state || {};

    const fetchParticipantMandatoryForum = useCallback(async () => {

        try {
            const { data } = await getTypeUserRole(id_type_forum ?? forum_type)

            setParticipantList(data.results)

        } catch { }

    }, [id_type_forum, participantList])


    const fetchdeleteUserTypeRole = useCallback(async () => {

        setLoading(true);

        try {

            await deleteTypeUserRoleAPI(Number(onlyParticipant?.role_id))

            setNotification({
                status: "success",
                message: "Usuário removido com sucesso.",
                title: "Usuário Removido",
                actived: true,
                onClose: () => closeNotification(),
            });

            onClose && onClose()

        } catch {

            setNotification({
                status: "error",
                message: "Não foi possível remover o usuário. Tente novamente mais tarde.",
                title: "Erro ao Remover Usuário",
                actived: true,
                onClose: () => closeNotification(),
            });

        } finally {

            setLoading(false);
            fetchParticipantMandatoryForum()
        }
    }, [deleteTypeForumdata, onlyParticipant])


    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Remover, {onlyParticipant?.first_name}</h3>}
            open={open}
            height={"300px"}
            width="45%"
            onClose={() => {
                onClose && onClose()
            }}
            background="#f4f4f4"
            buttons={
                [
                    {
                        label: "Não, manter como está",
                        kind: "secondary",
                        onClick: () => {
                            onClose && onClose()
                        }
                    },
                    {
                        label: "Sim, remover participante",
                        kind: "dangerPrimary",
                        onClick: () => fetchdeleteUserTypeRole(),
                    }
                ]
            }
        >
            <div className="mx-auto text-start flex flex-col justify-center items-center  rounded-lg  p-3">
                <div>
                    <p>Tem certeza que gostaria de remover <b>{onlyParticipant?.first_name} {onlyParticipant?.last_name}</b> da lista de participantes do fórum? Esta ação não poderá ser revertida</p>
                </div>
            </div>
        </Modal>
    );
};