import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Modal } from "prosperita-dumbo-react";
import useLoading from "~/hooks/useLoading";
import { getProfilesAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import AppStorage from "~/services/storage";
import { useFormik } from "formik";
import useForum from "~/hooks/useForum";
import { getTypeUserRole, postTypeUserRole } from "~/services/api/forum";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "~/services/utils";
import useNotification from "~/hooks/useToast";

type IroleUser = {
    type_vote: string,
    first_name: string,
    last_name?: string,
    id_number?: string,
    current_date?: string
}

export const ModalMandatoryParticipant = ({
    onClose,
    open,
    renderList,
}: {
    open: boolean;
    onClose: () => void;
    renderList: IroleUser[]
}) => {

    const [clearFilesDrop, setclearFilesDrop] = useState<boolean>(false)

    const { setNotification, closeNotification } = useNotification()

    const { setLoading } = useLoading();

    const { profiles, setProfiles } = useProfile()

    const location = useLocation();

    const { id_type_forum } = location.state || {};

    const {
        setParticipantMandatory,
        setParticipantList,
        participantList,
        setModalMandatoryParticipant
    } = useForum()

    const { setFieldValue, values, resetForm } = useFormik({
        initialValues: { id: "", type_vote: "" },
        onSubmit: () => { }
    })

    const fetchParticipantMandatoryForum = useCallback(async () => {

        try {
            const { data } = await getTypeUserRole(id_type_forum)

            setParticipantList(data.results)

        } catch {
            setParticipantList([])
        }
    }, [id_type_forum, participantList])


    const refreshParticipantMandatory = () => {

        const updatedData = AppStorage.get("@participant_mandatory");

        if (updatedData) {

            setParticipantMandatory(
                JSON.parse(String(updatedData)) as IroleUser[]
            );
        }
        else {
            setParticipantMandatory([]);
        }
    };

    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {

            console.error(err);

        } finally {
            setLoading(false);
        }
    }, [profiles]);

    const listProfiles = useMemo(() => {
        return profiles?.map((profile) => ({
            label: `${profile?.user?.first_name} ${profile?.user?.last_name}`,
            value: String(profile.user.id)
        })).filter((profile) =>
            renderList.every((item) => Number(item?.id_number) !== Number(profile.value))
        );
    }, [profiles, renderList]);


    const user_data = profiles.filter((users) => {
        return users.user.id === Number(values.id)
    })

    const payload = {
        id_number: values.id,
        type_vote: values.type_vote,
        first_name: user_data[0]?.user?.first_name,
        last_name: user_data[0]?.user?.last_name,
        created_on: getFormattedDate()
    }

    const handleMandatory = () => {

        try {
            const participantMandatory = AppStorage.get("@participant_mandatory");

            let updatedMandatoryList;

            if (participantMandatory) {

                const parsedMandatory = JSON.parse(String(participantMandatory));
                updatedMandatoryList = Array.isArray(parsedMandatory)
                    ? [...parsedMandatory, payload]
                    : [payload];
            } else {

                updatedMandatoryList = [payload];
            }
            AppStorage.set("@participant_mandatory", JSON.stringify(updatedMandatoryList));

            console.warn("Updated mandatory list:", updatedMandatoryList);

        } catch (error) {

            console.warn("Error handling mandatory:", error);
        } finally {

            setModalMandatoryParticipant(false)
            setclearFilesDrop(true)
        }
    };

    const sendRoleUserAPI = useCallback(async () => {

        setLoading(true);

        try {

            await postTypeUserRole({
                forum_type: Number(id_type_forum),
                type_vote: values.type_vote,
                user: Number(values.id),
                user_status: "obrigatorio"
            })

            setNotification({
                status: "success",
                message: "Participante adicionado com sucesso.",
                title: "Edição bem-sucedida",
                actived: true,
                onClose: () => closeNotification()
            });

            setLoading(false);

        } catch {

            setNotification({
                status: "error",
                message: "Erro ao adicionar participante obrigatório.",
                title: "Erro ao editar",
                actived: true,
                onClose: () => closeNotification()
            });


        } finally {
            fetchParticipantMandatoryForum()
            setLoading(false)
            setModalMandatoryParticipant(false)
            setclearFilesDrop(true);
        }
    }, [values])


    useEffect(() => {
        if (clearFilesDrop) {
            setclearFilesDrop(false);
        }
    }, [values, clearFilesDrop]);

    useEffect(() => {
        fetchProfiles()
    }, []);


    return (
        <Modal
            label={<h3
                style={{
                    fontFamily: "IBM Plex Sans",
                    fontSize: "25px"
                }}
            >Adicionar participante obrigatório</h3>}
            open={open}
            width="50%"
            height="590px"
            background="#f4f4f4"
            radius
            onClose={() => {
                onClose && onClose()
                setclearFilesDrop(true)
                resetForm()
            }}
            buttons={
                [
                    {
                        label: "Cancelar",
                        kind: "ghost",
                        size: "large",
                        onClick: () => {
                            onClose && onClose()
                            setclearFilesDrop(true)
                            resetForm()

                        }
                    },
                    {
                        label: "Adicionar participante obrigatório",
                        kind: "primary",
                        size: "large",
                        disabled: values.type_vote === "" || values.id === "",
                        onClick: () => {
                            id_type_forum ?
                                sendRoleUserAPI()
                                : handleMandatory()
                            refreshParticipantMandatory()
                        }
                    },
                ]
            }
        >
            <div className="w-[99%] mx-auto h-[80%] flex flex-col items-center justify-center gap-[10px]">
                <div className="h-[1px] bg-[#c6c6c6] w-[100%]"></div>
                <div className="w-[100%]">
                    <Dropdown
                        noRadius
                        kind="fluid"
                        size="medium"
                        label="Usuário(s)"
                        options={listProfiles || []}
                        onChange={(event) => {
                            setFieldValue("id", event?.target?.value)
                        }}
                        borderNone
                        clearFiles={clearFilesDrop}
                        placeholder="Selecione um usuário"
                    />
                </div>
                <div className="w-[100%]">
                    <Dropdown
                        noRadius
                        kind="fluid"
                        size="medium"
                        label="Tipo de voto"
                        clearFiles={clearFilesDrop}
                        onChange={(event) =>
                            setFieldValue("type_vote", event.target.value)
                        }
                        options={[
                            {
                                label: "Vota",
                                value: "vota"
                            },
                            {
                                label: "Voto com veto",
                                value: "voto_com_veto"
                            },
                            {
                                label: "Nao vota",
                                value: "nao_vota"
                            }
                        ]}
                        borderNone
                        // onChange={(selectedOption, actionMeta) =>
                        //   handleSelectBankAccount(selectedOption, actionMeta)
                        // }
                        placeholder="Selecione o tipo de voto deste usuário"
                    />
                </div>
            </div>
        </Modal >
    );
};
