import ApiService from "../api";

type Inotification = {
    type: string,
    notification_type: string,
    user?: number,
    attend?: number,
    body: string,
    subject: string
}

export type ImailNotificationget = {
    attend: number;
    body: string;
    id: number;
    message_log_id: string;
    notification_type: string;
    recipient: string;
    seen_at: string | null;
    sent_at: string;
    subject: string;
    type: string;
    user: number;
}

export const getNotificationsInvite = (user: number) => {
    return ApiService.HttpGet<{ results: ImailNotificationget[] }>({
        route: `mail/notifications/?user=${user}&notification_type=App&seen_at_isnull=true%27`,
        token: true,
    });
};

export const postNotificationsInvite = (body: Inotification) => {
    return ApiService.HttpPost<unknown>({
        route: "mail/notifications/",
        body,
        token: true,
    });
};
export const pacthNotificationsInvite = (id: number) => {
    return ApiService.HttpPatch<unknown>({
        route: `mail/notifications/${id}/`,
        token: true,
    });
};
export const deleteNotificationsInvite = (id: number) => {
    return ApiService.HttpDelete<unknown>({
        route: `mail/notifications/${id}/`,
        token: true,
    });
};
