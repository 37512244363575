import { Tile } from "prosperita-dumbo-react"
import { ItypeValidate } from "./CreatForumGeneral"
import styled from "styled-components";
import useLoading from "~/hooks/useLoading";
import useForum from "~/hooks/useForum";
import { getTypeForum } from "~/services/api/forum";
import { useCallback, useEffect } from "react";

export const TextBody01 = styled.p`
  color: var(--Text-text-primary, #161616);
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;`;

export const Heading03 = styled.h3`
  color: var(--Text-text-primary, #161616);
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; 
`;

export const TypeForum = ({ onValidate, formik }: ItypeValidate) => {

    const { setFieldValue, values } = formik

    onValidate(values.type_forum !== "")

    const { setLoading } = useLoading();

    const {
        setlistTypeForum,
        listTypeForum,
    } = useForum()

    const fecthTypeForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeForum()

            setlistTypeForum(data.results)

        } catch {

        } finally {

            setLoading(false);
        }

    }, [listTypeForum])

    useEffect(() => {
        fecthTypeForum()
    }, [])

    return (
        <div className="grid grid-cols-3 gap-[20px] mt-[50px] w-full">
            {listTypeForum?.map((type_forum) => (
                <Tile
                    variations="radio"
                    name="forum"
                    key={type_forum.id}
                    onClick={() =>
                        setFieldValue("type_forum", type_forum.id)
                    }
                >
                    <div className="min-h-[250px] min-w-[250px] max-w-full p-[10px]">
                        <Heading03 className="text-start">
                            {/* type_forum?.name?.length > 40 ?
                        `${type_forum?.name.slice(0, 40)}...` */}
                            {
                                type_forum?.name}
                        </Heading03>
                        <TextBody01 className="text-start overflow-hidden text-ellipsis ">
                            {
                                // type_forum?.description?.length > 300 ?
                                // `${type_forum.description.slice(0, 300)}...` :
                                type_forum?.description}
                        </TextBody01>
                    </div>
                </Tile>
            ))}
        </div>

    )
}
