import { Modal } from "prosperita-dumbo-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import useUser from "~/hooks/useUser";
import { postAttendAPI } from "~/services/api/attend";
import { postAttendTaskFileAPI } from "~/services/api/attendTaskFile";
import { postAttendTaskMessageAPI } from "~/services/api/AttendTaskMessage";
import { Forum, postCreateForum, postForumTopicAPI, postTypeUserRole } from "~/services/api/forum";
import { getNotificationsInvite, postNotificationsInvite } from "~/services/api/notifications";

type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum_type?: string,
    values: Values
}
type IfileType = {
    lastModified: number,
    lastModifiedDate: unknown,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string
}
type Values = {
    type_forum: string,
    title_forum: string,
    deadline: string,
    forum_description: string,
    file: IfileType[]
    files: IfileType[]
    guests: string
    vote_deadline: string,
    fund: string
}

export const ModalCreateForum = ({ open, onClose, values }: ItypeModal) => {

    const { setLoading } = useLoading()

    const navigate = useNavigate()

    const {
        setInviteNotification,
        inviteNotification,
        setNotification,
        closeNotification
    } = useNotification()


    const [forumData, setforumData] = useState<Forum>()

    const {
        userProfile,
    } = useUser();

    const {
        participantList,
        saveFilesForum,
        setSaveFilesForum
    } = useForum()

    const fetchNotificatonFiltered = useCallback(async () => {

        try {
            const { data } = await getNotificationsInvite(Number(userProfile?.user?.id));

            setInviteNotification(data.results)

        } catch (e) {

            console.error(e);
        }
    }, [userProfile, inviteNotification])

    const sendCreatForum = useCallback(async () => {

        // const formData = new FormData();

        // values?.file?.forEach((file) => {

        //     if (file instanceof File) {

        //         formData.append('file', file);
        //     }
        // });

        // formData.append("sender", String(userProfile?.id || ""));
        // formData.append("description", `${values.forum_description}`);

        setLoading(true)

        try {

            const { data } = await postAttendAPI({
                client: userProfile?.id || 0,
                fund: values.fund ? Number(values.fund) : null,
                message: values.forum_description,
                type: 49,
                area: 46
            })

            const { data: forum } = await postCreateForum({
                attend: String(data.id),
                description: values.forum_description,
                forum_type: values.type_forum,
                name: values.title_forum,
                vote_deadline: values.vote_deadline,
                forum_status: "aberto",
            })

            if (forum) {
                setforumData(forum);
                await sendParticipant(forum); // Passa o fórum diretamente
            }

            await postForumTopicAPI({
                content: values.forum_description,
                forum: Number(forum.id),
                title: values.title_forum,
                user: userProfile?.id || 0
            })

            if (saveFilesForum.length > 0) {

                //const { data: id_file } = await postAttendTaskFileAPI(formData);

                const uploadPromises = saveFilesForum?.map((file) => {

                    const formData = new FormData();
                  
                    if (file instanceof File) {
                        
                        formData.append("file", file);
                    }

                    // formData.append("sender", String(userProfile?.id || ""));
                    formData.append("attend", String(data.id));
                    formData.append("sender", String(userProfile?.id || ""));
                    formData.append("description", `${values.forum_description}`);

                    return postAttendTaskFileAPI(formData);
                });

                // await postAttendTaskFileAPI(formData);

                await Promise.all(uploadPromises);

                // await postForumAttachment({
                //     file: id_file.id,
                //     forum: Number(forum.id),
                //     forum_type: values.type_forum,
                //     topic: topic.id
                // })
            }

            const uniqueUsers = new Set(); // Para armazenar os IDs únicos

            const uploadsInvites = participantList?.map((list) => {

                const userId = list.user.id;
                const user_status = list.user_status === "obrigatorio";

                // Se o usuário ainda não foi adicionado, adiciona e env
                // ia o convite
                if (!uniqueUsers.has(userId)) {

                    uniqueUsers.add(userId);

                    const inviteData = {
                        attend: data.id,
                        body: user_status ? "A participação neste fórum é obrigatória." : "Você foi convidado para participar deste fórum.",
                        subject: `Convite para participar do fórum: ${forum?.name} #${forum?.id}`,
                        notification_type: "App",
                        type: "user_invite",
                        user: userId,
                    };

                    return postNotificationsInvite(inviteData);
                }
                // Se o usuário já foi convidado, não faz nada
                return null;
            }).filter(Boolean); // Remove os valores nulos (caso algum usuário seja duplicado)

            if (uploadsInvites && uploadsInvites.length > 0) { // Exibe a quantidade de requisições
                await Promise.all(uploadsInvites);
            }

            const userIds = participantList?.map((users) => users?.user?.id).join(',');

            await postAttendTaskMessageAPI({
                sender: "client",
                message: `Convidou ${userIds}`,
                subject: String(userProfile?.id),
                attend: forum?.attend,
            })

            setNotification({
                status: "success",
                message: "O fórum foi criado com sucesso.",
                title: "Fórum criado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            setSaveFilesForum([])

            navigate("/app/forum/list-forum/")

        } catch {

            setNotification({
                status: "error",
                message: "Não foi possível criar o fórum no momento, tente novamente mais tarde ou contate o administrador do sistema",
                title: "Falha ao criar fórum",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false)
            fetchNotificatonFiltered()
            onClose && onClose()
        }
    }, [values, userProfile, participantList, forumData, saveFilesForum])

    console.log(saveFilesForum)


    const sendParticipant = useCallback(async (forum: Forum) => {

        try {
            const loggedUserId = Number(userProfile?.user?.id);

            if (!loggedUserId) {
                throw new Error("Usuário não identificado.");
            }
            const isUserInList = participantList?.some(participant => participant.user.id === loggedUserId);

            if (!isUserInList && forum) {
                await postTypeUserRole({
                    forum_type: Number(values?.type_forum),
                    type_vote: "voto_com_veto",
                    user: loggedUserId,
                    user_status: "obrigatorio",
                });

                await postNotificationsInvite({
                    body: "A participação neste fórum é obrigatória.",
                    subject: `Convite para participar do fórum: ${forum.name} #${forum.id}`,
                    notification_type: "App",
                    type: "user_invite",
                    user: loggedUserId,
                });
            }
        } catch (error) {

            console.error("Erro ao enviar participante:", error);
        }
    }, [userProfile, participantList, values]);



    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Criar novo fórum</h3>}
            open={open}
            height={"280px"}
            width="45%"
            onClose={() => {
                onClose && onClose();
            }}
            background="#ffffff"
            buttons={[
                {
                    label: "Não, manter como está",
                    kind: "secondary",
                    onClick: () => {
                        onClose && onClose();
                    },
                },
                {
                    label: "Sim, criar novo fórum",
                    kind: "dangerPrimary",
                    onClick: async () => {
                        sendCreatForum();
                        // await sendParticipant();
                    },
                },
            ]}
        >
            <p className="w-[97%] mx-auto">Tem certeza que gostaria de criar este fórum? </p>
        </Modal>
    );
};
