import { TableBasic, Tag } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo } from "react"
import useForum from "~/hooks/useForum"
import { ModalInviteParticipant } from "../CreatForum/ModalInviteParticipant"
import useLoading from "~/hooks/useLoading"
import { getForumVoteUser, getTypeUserRole } from "~/services/api/forum"
import useUser from "~/hooks/useUser"
import { useLocation } from "react-router-dom"
import { formatarDataISO } from "~/services/utils"
import { getAttendHistoryAPI } from "~/services/api/attend"
import useAttend from "~/hooks/useAttend"
import { getProfilesAPI } from "~/services/api/profile"
import useProfile from "~/hooks/useProfile"

const status_name_vote: { [key: string]: JSX.Element } = {
  aprovado:
    <div className="w-[120px]">
      <Tag position="center" type="green">
        <p className="p-1 m-0">Aprovar</p>
      </Tag>
    </div>,
  reprovado:
    <div className="w-[120px]">
      <Tag position="center" type="red">
        <p className="p-1 m-0">Rejeitar</p>
      </Tag>
    </div>,
  vetado:
    <div className="w-[120px]">
      <Tag position="center" type="red">
        <p className="p-[6px] m-0">Vetado</p>
      </Tag>
    </div>,
  pendente_de_votacao:
    <div className="w-[120px]">
      <Tag position="center" type="border">
        <p className="p-[6px] m-0">Condicionado</p>
      </Tag>
    </div>
}

// const status_name: { [key: string]: JSX.Element } = {
//   voto_pendente:
//     <div className="w-[120px]">
//       <Tag position="center" type="border">
//         <p className="p-1 m-0">Voto Pendente</p>
//       </Tag>
//     </div>,
//   voto_realizado:
//     <div className="w-[120px]">
//       <Tag position="center" type="inverse">
//         <p className="p-[9px] m-0">Voto Realizado</p>
//       </Tag>
//     </div>,
//   vetado:
//     <div className="w-[120px]">
//       <Tag position="center" type="border">
//         <p className="p-[9px] m-0">Vetado</p>
//       </Tag>
//     </div>
// }

const voteDict: { [key: string]: string } = {
  vota: "Vota",
  nao_vota: "Não Vota",
  voto_com_veto: "Vota com Veto"
}

export const Participants = () => {

  const { userProfile } = useUser();

  const location = useLocation();

  const { id } = location.state || {};

  const { profiles, setProfiles } = useProfile();

  const {
    setmodalInviteParticipant,
    modalInviteParticipant,
    participantList,
    listForumFiltered,
    setParticipantList,
    listForumVoteUser,
    setlistForumVoteUser,
    setVote,
  } = useForum()

  const { setLoading } = useLoading()

  const {
    setHistory,
    history,
  } = useAttend();

  const fetchProfiles = useCallback(async () => {

    setLoading(true);

    try {
      const { data } = await getProfilesAPI({ mode: "list" });

      setProfiles(data.results.reverse());

      setLoading(false);

    } catch (err: unknown) {

      setLoading(false);
    }
  }, [setProfiles, setLoading]);



  const fetchParticipantMandatoryForum = useCallback(async () => {

    setLoading(true);

    try {

      const { data } = await getTypeUserRole(listForumFiltered?.forum_type?.id)

      setParticipantList(data.results)

    } catch {

      setParticipantList([])

    } finally {

      setLoading(false);
    }

  }, [participantList, listForumFiltered])


  const fetchForumVote = useCallback(async () => {

    setLoading(true);

    try {
      const { data } = await getForumVoteUser(id)

      setlistForumVoteUser(data.results)

    } catch {

    } finally {

      setLoading(false);
    }
  }, [listForumVoteUser])


  const listForumVote = useMemo(() => {
    return listForumVoteUser?.flatMap((forum) => {
      return forum?.forum_type_user_roles?.filter((userRole) => {
        return forum.user === userRole.user.id;
      }).map((userRole) => {
        return {
          ...userRole,
          vote_decision: forum.vote_decision,
          vote_status: forum.vote_status,
          forum: forum.forum,
          vote_date: forum.updated_on
        };
      });
    });
  }, [listForumVoteUser]);

  const listFilteredParticipants = useMemo(() => {
    return participantList?.map((participant) => {
      const forum = listForumVote?.find((forum) =>
        forum.user.id === participant.user.id && forum.forum === Number(listForumFiltered?.id));
      return {
        ...participant,
        vote_decision: forum ? forum.vote_decision : "",
        vote_status: forum ? forum.vote_status : "",
        forum: forum?.forum,
        vote_date: forum?.vote_date
      };
    });
  }, [participantList, listForumVote]);

  // const renderlistForum = useMemo(() => {
  //   return listFilteredParticipants?.map((forum) => ({
  //     "Nome do participante": `${forum?.user?.first_name} ${forum?.user?.last_name}`,
  //     "Tipo de voto": voteDict[forum?.type_vote],
  //     "Status":
  //       forum?.type_vote === "nao_vota" ?
  //         "" : status_name[forum?.vote_status] ||
  //         <div className="w-[120px]">
  //           <Tag position="center" type="border">
  //             <p className="p-[6px] m-0">Voto pendente</p>
  //           </Tag>
  //         </div>,
  //   }))
  // }, [participantList, listForumVote])

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(listForumFiltered?.attend));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [listForumFiltered, id]);


  const renderListForumClosed = useMemo(() => {
    return listFilteredParticipants?.map((forum) => {
      const isPendingVote =
        (forum?.type_vote === "vota" || forum?.type_vote === "voto_com_veto") &&
        forum?.vote_decision === "";

      return {
        "Nome do participante": `${forum?.user?.first_name} ${forum?.user?.last_name}`,
        "Tipo de voto": voteDict[forum?.type_vote],
        "Vote": status_name_vote[forum?.vote_decision] || (
          isPendingVote && (
            <div className="w-[120px]">
              <Tag position="center" type="border">
                <p className="p-[5px] m-0">Voto Pendente</p>
              </Tag>
            </div>
          )
        ),
      };
    });
  }, [participantList, listFilteredParticipants]);

  const isUserInvited = listFilteredParticipants?.some(
    (participant) => participant.user.id === userProfile?.user.id
  );

  useEffect(() => {
    fetchParticipantMandatoryForum()
    fetchForumVote()
    fetchHistory()
    fetchProfiles()
  }, [])


  useEffect(() => {
    const hasVoted = listFilteredParticipants?.some((user) => (
      user.user.id === userProfile?.user.id && user.vote_status === "voto_realizado"
      && listForumFiltered?.forum_type?.id === user.forum
    ));
    setVote(hasVoted || false);

  }, [listFilteredParticipants, userProfile?.user.id]);

  return (
    <div>
      <TableBasic
        search={listForumFiltered?.forum_status !== "encerrado" || !isUserInvited}
        columnTitles={
          [
            "Nome do participante",
            "Tipo de voto",
            listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado" ? "Voto" : "Status",
          ]
        }
        pageSizes={[6]}
        buttons={
          listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado" || !isUserInvited
            ?
            [] :
            [
              {
                size: "small",
                label: "Convidar participante",
                kind: "primary",
                position: "right",
                dimension: "37",
                onClick: () => setmodalInviteParticipant(true),
                disabled: !userProfile?.type?.includes("tool_manager")
              }
            ]}
        rowData={
          // (listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado") && renderListForumClosed
          //   ? renderListForumClosed
          // : 
          renderListForumClosed || []
          // renderlistForum || []
        }
      />
      <div style={{ fontFamily: "IBM Plex Sans" }} className="w-[95%]">
        <h5 className="mt-[40px] mb-[50px] font-semibold">Registros</h5>
        {listFilteredParticipants?.map((list) => {
          return (
            list.vote_status && (
              <div className="my-[30px] p-0">
                <div className="flex gap-[10px] items-center my-[20px]">
                  <h4 className="text-[18px] m-0 p-0 text-lg font-semibold" key={list?.user?.id}>
                    {list?.user?.first_name}  {list?.user?.last_name}
                  </h4>
                  <p className="m-0 p-0 text-[#525252]">registrou o voto</p>
                  <p className="m-0 p-0 text-[#525252]">{list?.vote_date && formatarDataISO(list?.vote_date)}</p>
                </div>
                <div className="bg-[#c6c6c6] w-[100%] h-[1px]"></div>
              </div>
            )
          )
        })}
        {history
          ?.filter((list) => /^Votou/.test(String(list?.actual_info?.message)))
          ?.map((list, id) => {
            const messageWithoutVotou = String(list?.actual_info?.message).replace(/^Votou\s*/, ""); // Remove "Votou" do início
            return (
              list.trigger && (
                <div key={id} className="m-0 p-0 my-[30px]">
                  <div className="flex flex-wrap items-center gap-3">
                    <h4 className="text-lg font-semibold p-0 m-0">{list?.trigger}</h4>
                    <p className="text-lg p-0 m-0 text-[#525252]">{messageWithoutVotou}</p>
                    <h4 className="text-[18px] m-0 p-0">{formatarDataISO(list?.date)}</h4>
                  </div>
                  <div className="my-3 bg-[#c6c6c6] w-[100%] h-[1px]"></div>
                </div>
              )
            );
          })}
        {history
          ?.filter((list) => /^Convidou (\d+(,\d+)*)$/.test(String(list?.actual_info?.message))) // Filtra mensagens que começam com "Convidou" e terminam com números
          ?.map((list, id) => {
            const numbers = String(list?.actual_info?.message)?.match(/\d+/g)?.map(Number) || [];
            const filteredProfiles = profiles.filter((user) => numbers.includes(Number(user?.user?.id)));
            return (
              list.trigger && (
                <div key={id} className="m-0 p-0 my-[30px]">
                  <div className="flex flex-wrap items-center gap-3">
                    <h4 className="text-lg font-semibold p-0 m-0">{list?.trigger}</h4>
                    <h4 className="text-lg p-0 m-0">Convidou</h4>
                    {filteredProfiles.length > 1 ? (
                      <div className="flex flex-col gap-1">
                        {filteredProfiles.map((profile) => (
                          <span key={profile?.user?.id} className="text-base text-black font-semibold">
                            {profile?.user?.first_name} {profile?.user?.last_name}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <span className="text-base text-black font-semibold">
                        {filteredProfiles[0]?.user?.first_name} {filteredProfiles[0]?.user?.last_name}
                      </span>
                    )}
                  </div>

                  <h4 className="text-[18px] m-0 p-0">{formatarDataISO(list?.date)}</h4>

                  <div className="my-3 bg-[#c6c6c6] w-full h-[1px]"></div>
                </div>
              )
            );
          })}
        <div>
          <div className="flex gap-[10px] items-center my-[30px]">
            <h4 className="text-[20px] m-0 p-0 text-lg font-semibold" >
              {listForumFiltered?.name}
            </h4>
            <p className="m-0 p-0">Alterou o status para</p>
            <h4 className="text-[18px] m-0 p-0 font-semibold" >{listForumFiltered?.forum_status}</h4>
            <h4 className="text-[18px] m-0 p-0">{formatarDataISO(String(listForumFiltered?.updated_on))}</h4>
          </div>
          <div className="bg-[#c6c6c6] w-[100%] h-[1px]"></div>
        </div>
      </div>
      <ModalInviteParticipant
        forum_type={listForumFiltered && String(listForumFiltered?.forum_type?.id)}
        open={modalInviteParticipant}
        onClose={() => setmodalInviteParticipant(false)} />
    </div>
  )
}
