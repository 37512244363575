import { ItypeModal } from "prosperita-dumbo-react/dist/types/Components/Modal";
import { Modal } from "prosperita-dumbo-react";
import { useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum";

export const ModalEditTypeForum = ({ open, onClose }: ItypeModal) => {

    const navigate = useNavigate()

    const {
        editTypeForum
    } = useForum()

    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Editar tipo de fórum</h3>}
            open={open}
            height={"350px"}
            width="45%"
            onClose={() => {
                onClose && onClose()
            }}
            background="#f4f4f4"
            buttons={
                [
                    {
                        label: "Não, manter como está",
                        kind: "secondary",
                        onClick: () => {
                            onClose && onClose()
                        }
                    },
                    {
                        label: "Sim, editar tipo de fórum",
                        kind: "dangerPrimary",
                        onClick: () => {
                            navigate("/app/forum/new-type-forum/", {
                                state: { id_type_forum: editTypeForum?.id }
                            })
                        },
                    }
                ]
            }
        >
            {/* <div className="h-[2px] bg-[#c6c6c6] w-[100%] mt-[30px] mb-0"></div> */}
            <p className="w-[97%] mx-auto">
                Tem certeza que gostaria de editar este tipo de fórum? </p>
        </Modal>
    );
};