import styled from "styled-components"
import { ItypeValidate } from "./CreatForumGeneral"
import { addHoursToDate, formatarDataAtual, getFormattedDateTime } from "~/services/utils"
import { Margin } from "prosperita-dumbo-react"
import { FileItem } from "~/components/FileItem"
import useForum from "~/hooks/useForum"
import useUser from "~/hooks/useUser"

export const TextFirst = styled.p`
color:  #525252;
font-family: "IBM Plex Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.32px;
margin: 0;
padding: 0;
`

export const Summary = ({ onValidate, formik }: ItypeValidate) => {

  const { values } = formik

  const {
    userProfile,
  } = useUser();

  onValidate(true)

  const {
    participantList,
    onlyTypeForum,
    saveFilesForum
  } = useForum()

  return (

    <div className="w-[100%]">
      <h4 className="text-[18px]">Requisitos</h4>
      <p className="m-[0] p-0">{onlyTypeForum?.description}</p>
      <Margin top={20} />
      <div className="w-[100%] flex justify-between my-[20px] p-0">
        <section className="m-[0] p-0">
          <TextFirst>Solicitante</TextFirst>
          <h6 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[14px] font-normal leading-[18px] tracking-[0.16px]">{userProfile?.name}</h6>
        </section>
        <section className="m-0 p-0">
          <TextFirst>Data</TextFirst>
          <h6 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[14px] font-normal leading-[18px] tracking-[0.16px]">{getFormattedDateTime()}</h6>
        </section>
      </div>

      <h4 className="text-[20px]">Detalhes da fórum</h4>
      <div className="flex justify-between w-[400px] my-[20px]">
        <div>
          <TextFirst>Título do fórum</TextFirst>
          <h5 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[17px] font-normal leading-[18px] tracking-[0.16px]">{values.title_forum || ""}</h5>
        </div>
        <div>
          <TextFirst>Prazo final para votação</TextFirst>
          <h5 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[17px] font-normal leading-[18px] tracking-[0.16px] mt-[5px]">{addHoursToDate(values.vote_deadline, formatarDataAtual()) || ""}</h5>
        </div>
      </div>
      <div className="my-[10px]">
        <TextFirst>Descrição do fórum</TextFirst>
        <p>{values?.forum_description}</p>
      </div>

      <div className="flex justify-between w-[70%] my-[60px]">
        <div>
          <h5>Participantes obrigatórios</h5>
          <div>
            {participantList &&
              participantList.map((user) => (
                user.user_status === "obrigatorio" && <div key={user?.user?.id}>{user?.user?.first_name} {user?.user?.last_name}</div>
              ))}
          </div>
        </div>
        <div>
          <h5>Participantes convidados</h5>
          <div>
            {participantList &&
              participantList.map((user) => (
                user.user_status === "convidado" && <div key={user?.user?.id}>{user?.user?.first_name} {user?.user?.last_name}</div>
              ))}
          </div>
        </div>
        <div>
          <h5>Participantes {participantList?.length}</h5>
        </div>
      </div>
      <div>
        <TextFirst>Anexos</TextFirst>
        <Margin top={10} />
        <div className="flex w-full flex-wrap gap-2">
          {saveFilesForum && Array.from(saveFilesForum || []).flatMap((data) => <FileItem fileName={data.name} />)}
        </div>
      </div>
    </div >
  )
}
