import { Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import { patchTypeForum, postCreatTypeForum, postTypeUserRole } from "~/services/api/forum";
import AppStorage from "~/services/storage";


type ItypeForm = {
    name: string;
    description: string;
    requirements: string;
    has_fund: boolean;
};

export const ModalCreatTypeForum = ({
    onClose,
    open,
}: {
    open: boolean;
    onClose: () => void;
}) => {

    const { setLoading } = useLoading();

    const location = useLocation();

    const { id_type_forum } = location.state || {};

    const navigate = useNavigate()

    const { setNotification, closeNotification } = useNotification()

    const {
        participantList,
        setParticipantMandatory,
        participantMandatory,
        setModalCreatTypeForum,
        isForumTypePresent
    } = useForum()

    function getFormTypeForum(): ItypeForm | null {

        const form_type_forum = AppStorage.get("@form_type_forum");

        if (form_type_forum) {
            let parsedData: ItypeForm | null;
            try {
                parsedData = JSON.parse(String(form_type_forum)) as ItypeForm;
            } catch (error) {
                console.error("Erro ao parsear o JSON:", error);
                parsedData = null;
            }
            return parsedData;
        }
        return null;
    }

    const result = getFormTypeForum();


    const updateTypeForumAPI = useCallback(async () => {

        setLoading(true);

        try {
            await patchTypeForum(Number(id_type_forum), {
                has_fund: result?.has_fund || false,
                name: result?.name || "",
                description: result?.description || "",
                requirements: result?.requirements || ""
            })

            setNotification({
                status: "success",
                message: "O tipo de fórum foi editado com sucesso.",
                title: "Editado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            setModalCreatTypeForum(false)

            navigate("/app/forum/forum-type/")

        } catch (error) {

            setNotification({
                status: "error",
                message: "Não foi possível editar o tipo de fórum no momento, tente novamente mais tarde ou contate o administrador do sistema.",
                title: "Falha ao editar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false);
        }

    }, [id_type_forum])

    const filterParticipants = participantList?.map((users) => ({
        id_number: String(users.user.id),
        last_name: String(users.user.last_name),
        first_name: String(users.user.first_name),
        type_vote: users.type_vote,
        created_on: users.created_on
    }))

    const fetchTypeForumandRoleProfile = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await postCreatTypeForum({
                has_fund: result?.has_fund || false,
                name: result?.name || "",
                description: result?.description || "",
                requirements: result?.requirements || ""
            })

            if (participantMandatory) {
                const fetchPromises = [
                    ...new Map([...(filterParticipants || []),
                    ...participantMandatory].map(item => [item.id_number, item])).values()
                ].map((user_data) => {

                    postTypeUserRole({
                        forum_type: data.id,
                        type_vote: user_data.type_vote,
                        user: Number(user_data.id_number),
                        user_status: "obrigatorio"
                    })
                })

                await Promise.all(fetchPromises);

                setNotification({
                    status: "success",
                    message: "O novo tipo de fórum foi criado com sucesso.",
                    title: "Criado com sucesso",
                    actived: true,
                    onClose: () => closeNotification()
                });
            }

            setModalCreatTypeForum(false)

            navigate("/app/forum/forum-type/")

        } catch {

            setNotification({
                status: "error",
                message: "Não foi possível criar o novo tipo de fórum no momento, tente novamente mais tarde ou contate o administrador do sistema",
                title: "Falha ao criar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {
            setLoading(false);
            AppStorage.remove("@form_type_forum")
            AppStorage.remove("@participant_mandatory")
            setParticipantMandatory([])
        }
    }, [])


    return (
        <Modal
            label="Criar novo tipo de fórum"
            open={open}
            width="56%"
            height="300px"
            background="#f4f4f4"
            radius
            onClose={onClose}
            buttons={
                [
                    {
                        label: "Não, manter como está",
                        kind: "secondary",
                        size: "large",
                        onClick: () => onClose && onClose()
                    },
                    {
                        label: "Sim, enviar para aprovação",
                        kind: "primary",
                        size: "large",
                        onClick: () => {
                            isForumTypePresent ?
                                updateTypeForumAPI() :
                                fetchTypeForumandRoleProfile()
                        }
                    },
                ]
            }
        >
            <p className="mx-auto w-[96%]">Tem certeza que gostaria de criar este tipo de fórum?</p>
        </Modal>
    )
}
