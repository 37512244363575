import {
    Breadcrumb,
    Button,
    Dropdown,
    Input,
    TableBatchActions,
    Toogle
} from "prosperita-dumbo-react"
import { useLocation, useNavigate } from "react-router-dom"
import Margin from "~/components/Margin"
import { useCallback, useEffect, useMemo, useState } from "react";
import useProfile from "~/hooks/useProfile";
import useLoading from "~/hooks/useLoading";
import { getProfilesAPI } from "~/services/api/profile";
import useForum, { IroleUserHook } from "~/hooks/useForum";
import { getTypeForumID, getTypeUserRole } from "~/services/api/forum";
import { ModalMandatoryParticipant } from "./ModalMandatoryParticipant";
import { useFormik } from "formik";
import AppStorage from "~/services/storage";
import trashIcon from "~/assets/trashIcon.png"
import { ModalRemoveRoleUser } from "./ModalRemoveRoleUser";
import { getFormattedDate } from "~/services/utils";
import * as Yup from 'yup';
// import useUser from "~/hooks/useUser";

type Values = {
    name: string;
    description: string;
    requirements: string;
    has_fund: boolean;
};


const initialValues: Values = {
    name: "",
    description: "",
    requirements: "",
    has_fund: false
};

export const NewTypeForum = () => {

    const location = useLocation();

    // const { userProfile } = useUser()

    const navigate = useNavigate()

    const { id_type_forum } = location.state || {};

    const { setProfiles } = useProfile()

    const [areParticipantsPresent, setAreParticipantsPresent] = useState<boolean>(false);

    const [hasFund, setHasFund] = useState<boolean>(false);

    const {
        modalMandatoryParticipant,
        setModalMandatoryParticipant,
        setParticipantMandatory,
        setParticipantList,
        participantList,
        setModalremoveParticipantTypeForum,
        modalremoveParticipantTypeForum,
        setOnlyParticipant
    } = useForum()

    const { setLoading } = useLoading();

    const validationSchema = Yup.object({
        name: Yup.string().required('Nome é obrigatório'),
        description: Yup.string().required('Descrição é obrigatória'),
        requirements: Yup.string().required('Requisitos são obrigatórios'),
        has_fund: Yup.boolean().required('Campo de fundo é obrigatório').isTrue('Fundo deve ser verdadeiro'),
    });

    const { values, handleChange, handleBlur, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => { }
    })
    const {
        setEditForumModal,
        participantMandatory,
        setOnlyTypeForum,
        onlyTypeForum,
        setEditTypeForum
    } = useForum()


    const participant = AppStorage.get("@form_type_forum");

    const parsedMandatory: Values | null = useMemo(() => {
        return participant ? JSON.parse(String(participant)) : null;
    }, [participant]);

    // const [isParentChecked, setIsParentChecked] = useState(false);

    // const [isParentIndeterminate, setIsParentIndeterminate] = useState(false);

    // const [checkedIndexes, setCheckedIndexes] =
    //     useState<boolean[]>(Array([...(participantList ?? []), ...(participantMandatory ?? [])]?.length).fill(false));

    // const handleParentCheckboxChange = () => {
    //     const newState = !isParentChecked;
    //     setCheckedIndexes(Array([...(participantList ?? []), ...(participantMandatory ?? [])]?.length).fill(newState));
    //     setIsParentChecked(newState);
    //     setIsParentIndeterminate(false);
    // };

    const fetchOnlyTypeForum = useCallback(async () => {

        try {
            const { data } = await getTypeForumID(Number(id_type_forum))

            setOnlyTypeForum(data)

        } catch (error) {

            console.log(error)

            // setFieldValue("name", "")
            // setFieldValue("description", "")
            // setFieldValue("requirements", "")
            // || !onlyTypeForum
            if (!parsedMandatory) {
                setFieldValue("name", "");
                setFieldValue("description", "");
                setFieldValue("requirements", "");
                return;
            }

        }

    }, [onlyTypeForum, id_type_forum, parsedMandatory])


    const fetchParticipantMandatoryForum = useCallback(async () => {

        try {
            const { data } = await getTypeUserRole(id_type_forum)

            setParticipantList(data.results)

            setAreParticipantsPresent(true)

        } catch {

            setAreParticipantsPresent(false)

            setParticipantList([])
        }
    }, [id_type_forum, participantList])


    const handleMandatory = () => {

        try {
            AppStorage.set("@form_type_forum", JSON.stringify({
                name: values.name,
                description: values.description,
                requirements: values.requirements,
                has_fund: hasFund,
                current_date: getFormattedDate()
            }));

        } catch (error) {

            console.warn(error);
        }
    };

    const handledeleteUser = (id_number: string) => {
        try {
            const participantMandatory = AppStorage.get("@participant_mandatory");

            if (participantMandatory) {

                const parsedMandatory = JSON.parse(String(participantMandatory));

                if (Array.isArray(parsedMandatory)) {

                    console.log("Original list:", parsedMandatory);

                    const updatedMandatoryList = parsedMandatory.filter(
                        (item: IroleUserHook) => {
                            console.warn("Comparing item name:", item.id_number, "with name to delete:", id_number);
                            return item.id_number !== id_number;
                        }
                    );
                    AppStorage.set("@participant_mandatory", JSON.stringify(updatedMandatoryList));

                    console.warn("Updated mandatory list after deletion:", updatedMandatoryList);
                } else {
                    console.warn("The stored data is not an array.");
                }
            } else {
                console.warn("No participants to delete.");
            }
        } catch (error) {
            console.warn("Error deleting user:", error);
        } finally {
            try {
                const updatedData = AppStorage.get("@participant_mandatory");

                if (updatedData) {
                    const parsedUpdatedData = JSON.parse(String(updatedData));
                    console.log("Updated data from storage:", parsedUpdatedData);

                    setParticipantMandatory(
                        parsedUpdatedData as IroleUserHook[]
                    );
                } else {
                    setParticipantMandatory([]);
                    console.log("No data found after deletion.");
                }
            } catch (finalError) {
                console.warn("Error updating state after deletion:", finalError);
            }
        }
    };

    const titlesColums = [
        <div className="flex items-center p-0 m-0 gap-[50px]">
            {/* <Checkbox
                checked={isParentChecked}
                indeterminate={isParentIndeterminate}
                onChange={handleParentCheckboxChange} /> */}
            <p className="p-0 m-0">Nome</p>
        </div>,
        "Tipo de voto",
        ""
    ];
    // const handleCheckboxChange = (rowIndex: number, data: any) => {

    //     setCheckedIndexes((prev) => {
    //         const updatedChecks = [...prev];
    //         updatedChecks[rowIndex] = !updatedChecks[rowIndex];

    //         const totalChecked = updatedChecks.filter(Boolean).length;
    //         const allChecked = totalChecked === data.length;
    //         const someChecked = totalChecked > 0;

    //         setIsParentChecked(someChecked);
    //         setIsParentIndeterminate(!allChecked && someChecked);

    //         return updatedChecks;
    //     });
    // };

    // const filterCheckds = checkedIndexes.filter((items) => items === true)
    // const someChecked = checkedIndexes.some((items) => items === true)

    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {

            console.error(err);

        } finally {

            setLoading(false);
        }
    }, []);


    const filterParticipants = participantList?.map((users) => ({
        id: String(users.user.first_name),
        last_name: String(users.user.last_name),
        first_name: String(users.user.first_name),
        type_vote: users.type_vote,
        id_number: String(users?.user?.id),
        role_id: users?.id,
        current_date: getFormattedDate()
    }))

    const participants: IroleUserHook[] = Array.isArray(participantMandatory)
        ? participantMandatory
        : [];

    const renderList: IroleUserHook[] = [
        ...new Map([...(filterParticipants || []), ...(participants || [])]
            .map(item => [item.id_number, item]))
            .values()
    ];

    // const isUserInvited = renderList?.some(
    //     (participant) => Number(participant.id_number) === Number(userProfile?.user.id)
    // );

    // console.log(isUserInvited)

    const listProfilesvote = useMemo(() => {

        const type_vot: { [key: string]: string } = {
            voto_com_veto: "Vota Com Veto",
            vota: "Vota",
            nao_vota: "Não Vota"
        }
        return renderList?.map((user) => ({
            check: (
                <div className="flex justify-start items-center w-[200px] gap-[50px]">

                    {/* <Checkbox
                            key={index}
                            checked={checkedIndexes[index]}
                            onClick={() => handleCheckboxChange(index, user)}
                        /> */}

                    <p className="p-0 m-0">
                        {user?.first_name && user.last_name ? `${user?.first_name} ${user?.last_name}` :
                            "não encontrado"}
                    </p>
                </div>
            ),
            "Tipo de voto": <div>{type_vot[user?.type_vote] ?? "Não especificado"}</div>,
            "delete":
                <div className="w-[20px]">
                    <Button
                        kind="ghost"
                        size="small"
                        noPadding="2px"
                        borderNone
                        icon={trashIcon}
                        onClick={() =>
                            areParticipantsPresent
                                ? (() => {
                                    setOnlyParticipant(user);
                                    setModalremoveParticipantTypeForum(true);
                                })()
                                : handledeleteUser(String(user?.id_number))
                        }
                    />
                </div>
        }));
    }, [participantMandatory, participantList]);


    //checkedIndexes

    // const participant = AppStorage.get("@form_type_forum")

    // const parsedMandatory: Values = JSON.parse(String(participant));

    // console.log(parsedMandatory.name)

    // useEffect(() => {

    //     if (onlyTypeForum?.has_fund !== undefined) {
    //         setHasFund(onlyTypeForum?.has_fund);
    //     }
    //     setFieldValue("name", onlyTypeForum?.name)
    //     setFieldValue("description", onlyTypeForum?.description)
    //     setFieldValue("requirements", onlyTypeForum?.requirements)

    // }, [onlyTypeForum])


    useEffect(() => {

        if (onlyTypeForum?.has_fund !== undefined) {
            setHasFund(onlyTypeForum.has_fund);
        }
        setFieldValue("name", parsedMandatory?.name ?? onlyTypeForum?.name);
        setFieldValue("description", parsedMandatory?.description ?? onlyTypeForum?.description);
        setFieldValue("requirements", parsedMandatory?.requirements ?? onlyTypeForum?.requirements);

    }, [onlyTypeForum]);

    const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasFund(e.target.checked)
        setFieldValue("has_fund", e.target.checked)
    };

    useEffect(() => {
        fetchOnlyTypeForum()
        fetchParticipantMandatoryForum()
        fetchProfiles()
        setEditForumModal(false)
    }, [])


    const isValidButton = values.description !== "" && values.requirements !== "" && values.name !== ""

    // useEffect(() => {
    //     setCheckedIndexes(Array([...(participantList ?? []), ...(participantMandatory ?? [])]?.length).fill(false));
    // }, [])

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Forum", onClick: () => navigate("/app/forum/list-forum/") },
                                    { crumb: "" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            Novo tipo de fórum
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[97%]  mx-auto">
                <div className="w-[100%] mx-auto">
                    <Margin mt={25} />
                    <section>
                        <h2 className="text-[25px] my-[20px]">Configurações</h2>
                        <div className="flex items-center m-0 p-0 gap-[20px] justify-center h-[60px]">
                            <Input
                                label="Nome (obrigatório)"
                                placeholder="Digite aqui o título do tipo de fórum"
                                size="large"
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={handleChange}
                            />
                            <div className="h-[100%] flex items-end d-none">
                                <Dropdown
                                    kind="fluid"
                                    label=""
                                    placeholder="Prazo de votação"
                                    size="small"
                                    name=""
                                    options={[]}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </section>
                    <Margin mt={15} />
                    <section
                        className="relative"
                    >
                        <div >
                            <Input
                                type="textarea"
                                label="Descrição (obrigatório)"
                                placeholder="Descreva o tipo de fórum"
                                size="large"
                                maxLength={500}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                            />
                        </div>
                        <p
                            style={{
                                right: "0",
                                top: "-5px",
                                position: "absolute",
                                fontSize: "15px",
                                color: "#525252"
                            }}

                        >{values?.description?.length}/{500}</p>
                    </section>
                    <Margin mt={15} />
                    <section className="relative">
                        <div >
                            <Input
                                rows={5}
                                type="textarea"
                                label="Requisitos mínimos (obrigatório)"
                                name="requirements"
                                onChange={handleChange}
                                placeholder="Descreva os requisitos"
                                size="large"
                                value={values?.requirements}
                                maxLength={500}
                            />
                        </div>
                        <p
                            style={{
                                right: "0",
                                top: "-5px",
                                position: "absolute",
                                fontSize: "15px",
                                color: "#525252"
                            }}

                        >{values?.requirements?.length}/{500}</p>
                    </section>
                    <div className="flex items-center justify-start">
                        <Toogle
                            size="default"
                            label=""
                            checked={hasFund}
                            onChange={(e) => handleToggleChange(e)}
                        />
                        <p className="p-0 m-0">Obrigatório vincular este tipo de fórum a um fundo</p>
                    </div>
                </div>
                <div>
                    <div style={{ position: "relative", }} className="bg-[#f4f4f4] mt-[50px]">
                        <div className="w-[100%]"
                            style={{ position: "absolute", top: "0", zIndex: "5" }}>
                            <TableBatchActions
                                label="Participantes obrigatórios"
                                search
                                messagedatalength="Nenhum Participante encontrado"
                                rowSizes="20"
                                // headerAction={{ active: someChecked, count: filterCheckds.length }}
                                sizeSearch="medium"
                                columnTitles={titlesColums}
                                columsKeys={[
                                    "",
                                    "check",
                                    "Tipo de voto",
                                    "delete"
                                ]}
                                pageSizes={[8, 20]}
                                actions={[]}
                                rowData={listProfilesvote}
                                buttons={[
                                    {
                                        kind: "secondary",
                                        label: "Adicionar participante obrigatório",
                                        position: "right",
                                        size: "medium",
                                        onClick: () => setModalMandatoryParticipant(true)
                                    }
                                ]}
                            />
                            <div className="w-[100%] flex justify-between items-center my-[40px]">
                                <Button
                                    kind="ghost"
                                    size="large"
                                    label="Cancelar"
                                    onClick={() => navigate("/app/forum/forum-type/")}
                                />
                                <Button
                                    kind="primary"
                                    size="large"
                                    label="Avançar"
                                    disabled={!isValidButton}
                                    onClick={() => {
                                        if (id_type_forum !== undefined) {
                                            navigate("/app/forum/summary-forum/", {
                                                state: { id_type_forum: id_type_forum }
                                            });
                                            handleMandatory();
                                        } else {
                                            navigate("/app/forum/summary-forum/", {
                                                state: { id_type_forum: undefined }
                                            });
                                            setEditTypeForum(undefined)
                                            handleMandatory();
                                        }
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalMandatoryParticipant
                renderList={renderList}
                open={modalMandatoryParticipant}
                onClose={() => setModalMandatoryParticipant(false)}
            />
            <ModalRemoveRoleUser
                open={modalremoveParticipantTypeForum}
                onClose={() => setModalremoveParticipantTypeForum(false)}
            />
        </div>
    )
}
