import constate from "constate";
import { useState } from "react";
import { Forum, IcommentTopicForum, IforumVote, IgetTypeForumTopic, IroleForumType, resultsTypeForum } from "~/services/api/forum";
import AppStorage from "~/services/storage";

type ItypeForm = {
    name: string;
    description: string;
    requirements: string;
    has_fund: boolean;
};
export type IroleUserHook = {
    type_vote: string,
    first_name: string,
    last_name?: string,
    id_number?: string,
    forum_type?: string,
    role_id?: number,
    created_on?: string
}

export type IFile = {
    id: number;
    attend: number;
    task: null;
    has_approve: boolean;
    file: string;
    description: string;
    created_on: string;
    updated_on: string;
    sender: number;
    target: number;
};


const [ForumProvider, useForum] = constate(() => {

    const [editForumModal, setEditForumModal] = useState<boolean>(false);
    const [deleteForumModal, setDeleteForumModal] = useState<boolean>(false);
    const [createForumModal, setCreateForumModal] = useState<boolean>(false);
    const [modalMandatoryParticipant, setModalMandatoryParticipant] = useState<boolean>(false);
    const [formTypeForum, setformTypeForum] = useState<ItypeForm>()
    const [listTypeForum, setlistTypeForum] = useState<resultsTypeForum[]>()
    const [onlyTypeForum, setOnlyTypeForum] = useState<resultsTypeForum>()
    const [deleteTypeForumdata, setdeleteTypeForumdata] = useState<resultsTypeForum>()
    const [editTypeForum, setEditTypeForum] = useState<resultsTypeForum>()
    const [participantList, setParticipantList] = useState<IroleForumType[]>()
    const [modalremoveParticipantTypeForum, setModalremoveParticipantTypeForum] = useState<boolean>(false)
    const [onlyParticipant, setOnlyParticipant] = useState<IroleUserHook>()
    const [modalCreatTypeForum, setModalCreatTypeForum] = useState<boolean>(false);
    const [modalVoteUser, setModalVoteUser] = useState<boolean>(false);
    const [isForumTypePresent, setIsForumTypePresent] = useState<boolean>(false);
    const [modalInviteParticipant, setmodalInviteParticipant] = useState<boolean>(false);
    const [modalCreatForum, setmodalCreatForum] = useState<boolean>(false);
    const [listForum, setListForum] = useState<Forum[]>();
    const [listForumFiltered, setListForumlistForumFiltered] = useState<Forum | null>();
    const [listTopic, setlistTopic] = useState<IgetTypeForumTopic[]>();
    const [listTopicOnly, setlistTopicOnly] = useState<IgetTypeForumTopic>()
    const [listForumVoteUser, setlistForumVoteUser] = useState<IforumVote[]>()
    const [commentTopicList, setCommentTopicList] = useState<IcommentTopicForum[]>()
    const [commentTopicListView, setCommentTopicListView] = useState<IcommentTopicForum[]>()
    const [vote, setVote] = useState<boolean>()
    const [filesForumAttend, setfilesForumAttend] = useState<IFile[]>()
    const [filterForumTopic, setFilterForumTopic] = useState<boolean>(false)
    const [modalDocument, setModalDocument] = useState<boolean>(false)
    const [modalDocumentData, setModalDocumentData] = useState<IFile>()
    const [saveFilesForum, setSaveFilesForum] = useState<File[]>([])

    const [participantMandatory, setParticipantMandatory] =
        useState<IroleUserHook[]>(() => {
            const storedData = AppStorage.get("@participant_mandatory");
            return storedData ? JSON.parse(String(storedData)) : [];
        });

    return {
        setSaveFilesForum,
        saveFilesForum,
        setModalDocumentData,
        modalDocumentData,
        setModalDocument,
        modalDocument,
        setFilterForumTopic,
        filterForumTopic,
        setCommentTopicListView,
        commentTopicListView,
        setfilesForumAttend,
        filesForumAttend,
        setVote,
        vote,
        setCommentTopicList,
        commentTopicList,
        setlistForumVoteUser,
        listForumVoteUser,
        setModalVoteUser,
        modalVoteUser,
        setlistTopicOnly,
        listTopicOnly,
        setlistTopic,
        listTopic,
        setListForumlistForumFiltered,
        listForumFiltered,
        setmodalCreatForum,
        modalCreatForum,
        setListForum,
        listForum,
        setmodalInviteParticipant,
        modalInviteParticipant,
        editForumModal,
        deleteForumModal,
        createForumModal,
        setDeleteForumModal,
        setCreateForumModal,
        setEditForumModal,
        modalMandatoryParticipant,
        setModalMandatoryParticipant,
        setParticipantMandatory,
        participantMandatory,
        setformTypeForum,
        formTypeForum,
        listTypeForum,
        setlistTypeForum,
        setOnlyTypeForum,
        onlyTypeForum,
        setdeleteTypeForumdata,
        deleteTypeForumdata,
        setEditTypeForum,
        editTypeForum,
        setParticipantList,
        participantList,
        setModalremoveParticipantTypeForum,
        modalremoveParticipantTypeForum,
        setOnlyParticipant,
        onlyParticipant,
        modalCreatTypeForum,
        setModalCreatTypeForum,
        setIsForumTypePresent,
        isForumTypePresent
    };
});

export { ForumProvider };

export default useForum;
