import { Breadcrumb, Button, Input, Tab, TabPane } from "prosperita-dumbo-react"
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum"
import useLoading from "~/hooks/useLoading";
import {
    getForumComment,
    getForumFilteredAPI,
    getForumTopicOnly,
    postForumComment
} from "~/services/api/forum";
import userIcon from "~/assets/User.png";
import { useFormik } from "formik";
import useUser from "~/hooks/useUser";
import Margin from "~/components/Margin";
import { formatarDataISO } from "~/services/utils";
import useProfile from "~/hooks/useProfile";
import { getProfilesAPI } from "~/services/api/profile";
import { sendIconSVG } from "~/assets/IconsSVG"
import useNotification from "~/hooks/useToast";



const initialValues = {
    forum_description: "",
    title_forum: ""
}

export const Comments = () => {

    const location = useLocation();

    const navigate = useNavigate()

    const { setLoading } = useLoading()

    const { setNotification, closeNotification } = useNotification()

    const {
        userProfile,
    } = useUser();

    const {
        setProfiles,
        profiles
    } = useProfile();

    const { forum, topic } = location.state || { forum: null, topic: null };

    const { handleChange, values, resetForm } = useFormik(({
        initialValues,
        onSubmit: () => { }
    }))

    const {
        listTopicOnly,
        setListForumlistForumFiltered,
        listForumFiltered,
        setlistTopicOnly,
        setCommentTopicList,
        commentTopicList
    } = useForum()

    const fetchForumAPI = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await getForumFilteredAPI(forum)

            setListForumlistForumFiltered(data)

        } catch {

        } finally {

            setLoading(false)
        }

    }, [listForumFiltered, forum])


    const fetchForumTopic = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getForumTopicOnly(topic);

            setlistTopicOnly(data);

        } catch (error) {

        } finally {

            setLoading(false);
        }
    }, [topic]);

    const fetchCommentForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getForumComment(topic)

            setCommentTopicList(data.results)

        } catch (error) {

        } finally {

            setLoading(false);
        }

    }, [commentTopicList, topic]);


    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {

            console.error(err);

        } finally {

            setLoading(false);
        }
    }, []);

    const fetchSendCommentForum = useCallback(async () => {

        setLoading(true);

        try {
            await postForumComment({
                content: values.forum_description,
                forum: forum,
                topic: topic,
                user: Number(userProfile?.id)
            });

            setNotification({
                status: "success",
                message: "O comentário foi criado com sucesso.",
                title: "Sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

        } catch (error) {

            setNotification({
                status: "error",
                message: "Ocorreu um erro ao criar o comentário. Tente novamente.",
                title: "Erro",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false);
            fetchCommentForum()
        }
    }, [topic, listTopicOnly, forum, values]);

    const matchingProfile = profiles.filter(profile => profile?.id === listTopicOnly?.user);

    useEffect(() => {
        fetchForumAPI();
        fetchForumTopic();
        fetchCommentForum()
        fetchProfiles()
    }, []);

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Painel", onClick: () => "" },
                                    { crumb: "Fórum" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            #{listForumFiltered?.id} - {listForumFiltered?.name}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[95%]  mx-auto mt-[30px]">
                <Tab width="100%">
                    <TabPane
                        width="100px"
                        position="center"
                        label={"Topicos"}
                        bordercolor=""
                        isActive={false}
                        onClick={() => navigate("/app/forum/activities/", { state: { id: forum } })} />
                    <TabPane
                        width="100%"
                        position="left"
                        label={listTopicOnly && listTopicOnly?.title || ""}
                        isActive
                        bordercolor=" rgb(10, 74, 135)"
                        onClick={() => { }}>
                        <div className="w-[100%] mt-[20px]">
                            <Margin mt={20} />
                            <div className="flex items-center m-0 p-0 gap-[22px] w-[100%]">
                                <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                                    <img src={userIcon} />
                                </div>
                                <div className="section m-0 p-0">
                                    <h2
                                        style={{ fontFamily: "IBM Plex Sans" }}
                                        className="text-[18px] m-0 font-[900]"
                                    >
                                        {matchingProfile.flatMap((user) => user?.name)}
                                    </h2>
                                    <h4
                                        style={{ fontFamily: "IBM Plex Sans" }}
                                        className="text-[17px] font-[400] m-0 mt-1"
                                    >
                                        {listTopicOnly?.created_on ? formatarDataISO(listTopicOnly?.created_on) : ""}
                                    </h4>
                                </div>
                            </div>
                            <Margin mt={20} />
                            <div style={{ borderRadius: "10px" }} className="bg-[#f4f4f4] min-h-[100px] p-[16px]">
                                <div className="w-[100%] justify-start flex flex-col">
                                    <p className="text-[25px] break-words">{[listTopicOnly?.title]}</p>
                                    <p className="text-[17px] break-words">{listTopicOnly?.content}</p>
                                </div>
                            </div>
                            <Margin mt={20} />
                            <div className="flex items-center m-0 p-0 gap-[22px] w-[100%]">
                                <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                                    <img src={userIcon} />
                                </div>
                                <div className="section m-0 p-0">
                                    <h2
                                        style={{ fontFamily: "IBM Plex Sans" }}
                                        className="text-[18px] m-0 font-[900]"
                                    >
                                        {userProfile?.name}
                                    </h2>

                                </div>
                                <div style={{ borderRadius: "30px" }} className="section m-0 p-1 bg-[#c6c6c6]">{"Rascunho"}</div>
                            </div>
                            <div className="w-[100%] mt-[20px]">
                                <Input
                                    type="textarea"
                                    cols={500}
                                    rows={15}
                                    autoComplete="off"
                                    value={values.forum_description}
                                    name="forum_description"
                                    onChange={handleChange}
                                    placeholder="Exemplo de Rich text format adicionado ao Text field"
                                />
                            </div>

                            <div className="w-[100%] flex justify-end items-center mb-[50px] mt-[10px]">
                                <Button
                                    label="Enviar resposta"
                                    kind="tertiary"
                                    size="large"
                                    icon={
                                        values.forum_description === "" ||
                                            listForumFiltered?.forum_status === "encerrado"
                                            || listForumFiltered?.forum_status === "vetado"
                                            ? "" : sendIconSVG}
                                    disabled={
                                        values.forum_description === "" ||
                                        listForumFiltered?.forum_status === "encerrado"
                                        || listForumFiltered?.forum_status === "vetado"
                                    }
                                    hasIconSvg
                                    onClick={() => {
                                        fetchSendCommentForum()
                                        resetForm()
                                    }}
                                />
                            </div>
                            {commentTopicList && commentTopicList?.map((comment) => {

                                const matchingProfiles = profiles.filter(profile => profile?.id === comment?.user);

                                return (
                                    <div className="my-[50px]">
                                        <div className="flex items-center m-0 p-0 gap-[22px] w-[100%]">
                                            <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                                                <img src={userIcon} />
                                            </div>
                                            <div className="section m-0 p-0">
                                                <h2
                                                    style={{ fontFamily: "IBM Plex Sans" }}
                                                    className="text-[18px] m-0 font-[900]"
                                                >
                                                    {matchingProfiles?.map(profile => (
                                                        <span key={profile?.id}>
                                                            {profile?.name}
                                                        </span>
                                                    ))}
                                                </h2>
                                                <h4
                                                    style={{ fontFamily: "IBM Plex Sans" }}
                                                    className="text-[17px] font-[400] m-0 mt-1"
                                                >
                                                    {comment?.created_on ? formatarDataISO(comment?.created_on) : ""}
                                                </h4>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-[30px] w-[100%] gap-2 break-words">
                                            {comment?.content}
                                        </div>
                                    </div>)
                            })
                            }
                        </div>
                    </TabPane>
                </Tab>
            </div>
        </div>
    )
}
