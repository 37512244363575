import { Modal, PasswordInput } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorIcon from "~/assets/Misuse.png";
import CheckIcon from "~/assets/Checkmark--filled.png";
import { toast } from "react-toastify";
import { postNewPasswordAPI } from "~/services/api/password";
import useUser from "~/hooks/useUser";
import useAuth from "~/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getProfilesAPI, patchProfileAPI } from "~/services/api/profile";
import AppStorage from "~/services/storage";
import { Input } from "prosperita-dumbo-react";
// ISendNewPassword
const notifySuccess = () => toast.success("Senha alterada com sucesso!");
const notifyError = () =>
  toast.error(
    "Não foi possível alterar a senha! Verifique se a senha atual está correta e tente novamente."
  );

type Values = {
  password: string;
  confirm_password: string;
  actual_password: string;
};

const initialValues: Values = {
  password: "",
  confirm_password: "",
  actual_password: "",
};

const validationSchema = Yup.object({});

const FirstLoginModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {

  const { setLoading } = useLoading();

  const { logout } = useAuth();

  const navigate = useNavigate();

  const { setOpenChangePasswordModal, setIsFirstLogin, userProfile } = useUser();

  const { setUserProfile } = useUser();

  const { resetForm, values, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => { }
  });

  const minLength = values.password.length >= 8;
  const hasNumber = /[0-9]/.test(values.password);
  const hasUpperCase = /[A-Z]/.test(values.password);
  const hasLowerCase = /[a-z]/.test(values.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);

  // const payloadAssociateNewAttendAreaProfile = useMemo(() => {
  //   // const password = AppStorage.get("@app:pass");
  //   return {
  //     current_password: values.actual_password,
  //     new_password: values.password,
  //   };
  // }, [values]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI();

      setUserProfile(data.results[0]);

    } catch (e) {

      console.error(e);
    }
  }, [setUserProfile]);


  const fetchAssociateAttendAreaProfile = useCallback(

    async () => {
      setLoading(true);

      try {
        await postNewPasswordAPI({
          current_password: values.actual_password,
          new_password: values.password,
        });
        await patchProfileAPI(
          {
            raw_data: { first_login: false, status: "active" }
          },
          String(userProfile?.id)
        );

        AppStorage.remove("@app:pass");

        fetchUserProfile();
        setOpenChangePasswordModal(false);
        resetForm();
        setLoading(false);
        setIsFirstLogin(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [
      setLoading,
      resetForm,
      setOpenChangePasswordModal,
      setIsFirstLogin,
      userProfile,
      fetchUserProfile,
      // payloadAssociateNewAttendAreaProfile,
      values
    ]
  );

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading=""
      size="sm"
      open={open}
      onSecondarySubmit={() => {
        logout();
        navigate("/login");
      }}
      onRequestClose={() => {
        onClose();
        resetForm();
        // logout();
      }}
      // primaryButtonDisabled={
      //   values.password === "" ||
      //   values.confirm_password === "" ||
      //   values.confirm_password !== values.password ||
      //   !minLength ||
      //   !hasLowerCase ||
      //   !hasNumber ||
      //   !hasSpecialChar ||
      //   !hasNumber ||
      //   !hasUpperCase
      // }
      primaryButtonText="Concluir"
      secondaryButtonText="Sair"
      onRequestSubmit={
        async () => {

          fetchAssociateAttendAreaProfile();

        }}
    >
      <div>
        <h3>Olá {userProfile?.user.first_name}, bem vindo ao portal Banvox</h3>
        <Margin mb={20} />
        <h4>Para continuar, primeiro cadastre uma nova senha</h4>
        <Margin mb={20} />
        <PasswordInput
          id="actual_password"
          name="actual_password"
          onChange={handleChange}
          value={values.actual_password}
          labelText="Digite sua senha atual"
          placeholder="Digite a senha enviada no seu e-mail"

        />
        <Margin mb={10} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={minLength ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Mínimo 8 caracteres</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={hasNumber ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Mínimo de 1 número</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img
              src={hasLowerCase && hasUpperCase ? CheckIcon : ErrorIcon}
              alt=""
            />
          </div>
          <Margin ml={10} />
          <p>Letras maiúsculas e minúsculas</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={hasSpecialChar ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Caracteres especiais</p>
        </div>
        <Margin mt={20} />

        <Margin mb={20} />

        {/* <PasswordInput
          id="password"
          name="password"
          labelText="Nova senha"
          placeholder="Sua nova senha"
          value={values.password}
          onChange={handleChange}
        /> */}
        <Input
          size="large"
          type="password"
          noRadius
          label="Confirmação de nova senha"
          id="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          placeholder="Sua nova senha"
        />
        <Margin mb={10} />
        {/* <PasswordInput
          id="confirm_password"
          name="confirm_password"
          labelText="Confirmação de nova senha"
          placeholder="Confirme sua nova senha"
          value={values.confirm_password}
          onChange={handleChange}
        /> */}
        <Input
          size="large"
          type="password"
          noRadius
          label="Confirmação de nova senha"
          name="confirm_password"
          value={values.confirm_password}
          onChange={handleChange}
          placeholder="Confirme sua nova senha"
        />
      </div>
      <Margin mb={50} />
    </Modal>
  );
};

export default FirstLoginModal;
