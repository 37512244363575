import { Breadcrumb, Button, Margin, Tab, TabPane, Tag } from "prosperita-dumbo-react"
import { Activities } from "./Activities"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Participants } from "./Participants"
import { Documents } from "./Documents"
import activityIcon from "~/assets/Activity (1).png"
import userIcon from "~/assets/users.png"
import TableIcon from "~/assets/Table-of-contents.png"
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom"
import { getForumFilteredAPI, getForumTopic, getForumVoteUser, getTypeUserRole, pacthForumFilteredAPI } from "~/services/api/forum"
import useLoading from "~/hooks/useLoading"
import useForum from "~/hooks/useForum"
import { ModalVoteUser } from "../ModalVoteUser"
import { addHoursToDate, formatarDataISO } from "~/services/utils"
import useUser from "~/hooks/useUser"
import useAttend from "~/hooks/useAttend"
import { getAttendDetailsforumAPI } from "~/services/api/attend"
import checkIcon from "~/assets/checkmark.png"
import lockIcon from "~/assets/lock.png"

type IrenderType = {
    content: JSX.Element,
    position?: "center" | "right" | "left"
    tab: "participants" | "activities" | "documents"
    size?: string
    label: string
    disabled?: boolean
    icon?: string
}[]

const TextTitle = styled.h4`
color: var(--Text-text-secondary, #525252);
font-family: "IBM Plex Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.16px;
`
const TextSecondary = styled.h4`
color: #000;
font-family: "IBM Plex Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; 
`

const status_name: { [key: string]: JSX.Element } = {

    aberto:
        <div>
            <Tag position="center" type="blue">
                <p className="p-[10px] m-0">Aberto</p>
            </Tag>
        </div>,
    pendente_de_votacao:
        <div className="w-[180px]">
            <Tag position="center" type="border">
                <p className="p-1 m-0">Condicionado</p>
            </Tag>
        </div>,
    encerrado:
        <div className="w-[110px]">
            <Tag position="center" type="inverse">
                <p className="p-[8px] m-0">Encerrado</p>
            </Tag>
        </div>,
    aprovado:
        <div className="w-[110px]">
            <Tag position="center" type="green">
                <p className="p-[6px] m-0">Aprovado</p>
            </Tag>
        </div>,
    vetado:
        <div className="w-[90px]">
            <Tag position="center" type="red">
                <p className="p-[6px] m-0">Vetado</p>
            </Tag>
        </div>,
    reprovado:
        <div className="w-[100px]">
            <Tag position="center" type="red">
                <p className="p-[9px] m-0">Reprovado</p>
            </Tag>
        </div>
}

export const SessionActivities = () => {

    const [tab, setTab] = useState<"activities" | "participants" | "documents">("activities")

    const { userProfile } = useUser();

    const location = useLocation();

    const { id } = location.state || {};

    const { setLoading } = useLoading()

    const navigate = useNavigate()

    const {
        setListForumlistForumFiltered,
        listForumFiltered,
        setlistTopic,
        listTopic,
        setModalVoteUser,
        modalVoteUser,
        vote,
        setlistForumVoteUser,
        listForumVoteUser,
        participantList,
        setVote,
        setParticipantList,
    } = useForum()

    const {
        attendDetails,
        setAttendDetails,
    } = useAttend();

    const fetchForumAPI = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await getForumFilteredAPI(id)

            setListForumlistForumFiltered(data)

        } catch (error) {

            console.warn(error)

        } finally {

            setLoading(false)
        }
    }, [id])


    const fetchAttends = useCallback(async () => {
        if (!listForumFiltered?.attend) return;


        try {
            const { data } = await getAttendDetailsforumAPI(listForumFiltered.attend);
            setAttendDetails(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [listForumFiltered]);


    const fetchForumTopic = useCallback(async () => {
        try {

            const { data } = await getForumTopic(id)

            setlistTopic(data.results.reverse())

        } catch {

        } finally {

            setLoading(false)
        }
    }, [listTopic, id])


    const fetchParticipantMandatoryForum = useCallback(async () => {

        try {

            const { data } = await getTypeUserRole(listForumFiltered?.forum_type?.id)

            setParticipantList(data.results)

        } catch {

            setParticipantList([])

        } finally {

            setLoading(false);
        }

    }, [participantList, listForumFiltered])


    const fetchForumVote = useCallback(async () => {

        try {
            const { data } = await getForumVoteUser(Number(listForumFiltered?.id))

            setlistForumVoteUser(data.results)

        } catch {

        } finally {

            setLoading(false);
        }
    }, [listForumVoteUser, listForumFiltered])

    const listForumVote = useMemo(() => {

        return listForumVoteUser?.flatMap((forum) => {
            return forum?.forum_type_user_roles?.filter((userRole) => {
                return forum.user === userRole.user.id;
            }).map((userRole) => {
                return {
                    ...userRole,
                    vote_decision: forum.vote_decision,
                    vote_status: forum.vote_status,
                    forum: forum.forum
                };
            });
        });
    }, [listForumVoteUser]);

    const listFilteredParticipants = useMemo(() => {
        return participantList?.map((participant) => {
            const forum = listForumVote?.find((forum) => forum.user.id === participant.user.id && forum.forum === Number(listForumFiltered?.id));
            return {
                ...participant,
                vote_decision: forum ? forum.vote_decision : "",
                vote_status: forum ? forum.vote_status : "",
                forum: forum?.forum
            };
        });
    }, [participantList, listForumVote]);

    const isUserInvited = listFilteredParticipants?.some(
        (participant) => participant.user.id === userProfile?.user.id
    );

    const renderTab: IrenderType = [

        {
            content: <Activities userNotIvite={!isUserInvited} />,
            label: "Atividades",
            tab: "activities",
            size: "120px",
            icon: activityIcon
        },
        {
            content: <Participants />,
            label: "Participantes",
            tab: "participants",
            size: "140px",
            icon: userIcon
        }, {
            content: <Documents userNotIvite={!isUserInvited} />,
            label: "Documentos",
            tab: "documents",
            size: "150px",
            icon: TableIcon
        }
    ]

    useEffect(() => {

        if (listForumFiltered?.id) {
            fetchForumVote();
            fetchParticipantMandatoryForum();
        }
    }, [listForumFiltered]);


    const usernoVote = listFilteredParticipants?.some((participant) => {
        return participant.user.id === userProfile?.user.id && participant.type_vote === "nao_vota";
    });

    const totalParticipants = participantList?.filter((participant) =>
        participant.type_vote === "vota" || participant.type_vote === "voto_com_veto").length || 0;

    const votesRealized = listForumVote?.filter((users_vote) =>
        users_vote?.vote_status === "voto_realizado")?.length || 0;


    useEffect(() => {

        if (!listFilteredParticipants || !userProfile?.user.id) return;

        const hasVoted = listFilteredParticipants.some((user) => (
            user.user.id === userProfile.user.id && user.vote_status === "voto_realizado"
        ));

        //deleteForumVoteUser(16)

        setVote(hasVoted || false);

    }, [listFilteredParticipants, userProfile?.user.id]);

    const votes_aprover = listFilteredParticipants?.filter((votes) => votes.vote_decision === "aprovado" || votes.vote_decision === "vetado")

    const votes_reprover = listFilteredParticipants?.filter((votes) => votes.vote_decision === "reprovado")

    const votes_annulled = listFilteredParticipants?.filter((votes) => votes.vote_decision === "pendente_de_votacao")


    const countVotes: Record<string, number> = (listFilteredParticipants || [])?.reduce(
        (acc, { vote_decision }) => {
            if (vote_decision) {
                acc[vote_decision] = (acc[vote_decision] || 0) + 1;
            }
            return acc;
        },
        {} as Record<string, number>
    );

    const mostFrequentVote = countVotes && Object.keys(countVotes).length > 0
        ? Object.keys(countVotes).reduce((a, b) => {
            if (a === "vetado") return a;
            if (b === "vetado") return b;
            return countVotes[a] > countVotes[b] ? a : b;
        })
        : null;


    useEffect(() => {
        fetchForumTopic()
    }, [id])

    useEffect(() => {
        fetchForumAPI();
    }, [fetchForumAPI]);

    useEffect(() => {
        fetchAttends();
    }, [fetchAttends]);


    useEffect(() => {

        const compareDatesAndUpdate = async () => {

            if (!listForumFiltered?.created_on || !listForumFiltered?.vote_deadline) return;

            const createdOn = new Date(listForumFiltered.created_on);
            const voteDeadline = String(listForumFiltered.vote_deadline);

            const match = voteDeadline.match(/^(\d+)_horas$/);

            if (match) {
                const hours = parseInt(match[1], 10);

                const deadlineDate = new Date(createdOn);
                deadlineDate.setHours(deadlineDate.getHours() + hours);

                const currentDate = new Date();

                if (currentDate >= deadlineDate && listForumFiltered?.forum_status === "aberto") {
                    try {
                        await pacthForumFilteredAPI(Number(listForumFiltered?.id), {
                            forum_type: String(listForumFiltered?.forum_type.id),
                            forum_status: "encerrado",
                            name: listForumFiltered?.name || "",
                            vote_deadline: voteDeadline,
                            attend: String(listForumFiltered?.attend),
                        });

                        console.warn("Fórum encerrado automaticamente.");
                    } catch (error) {
                        console.error("Erro ao encerrar o fórum:", error);
                    }
                }
            }
        };
        if (listForumFiltered) {
            compareDatesAndUpdate();
        }

    }, [listForumFiltered]);

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Meus Fóruns", onClick: () => navigate("/app/forum/list-forum/") },
                                    { crumb: "Fórum" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            #{listForumFiltered?.id} - {listForumFiltered?.name}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[97%] mx-auto flex gap-[30px] justify-start mt-[30px]">
                <div className="w-[77%]">
                    {isUserInvited ?
                        <Tab width="100%">
                            {renderTab.map((regs, index) => (
                                <TabPane
                                    key={index}
                                    width={regs.size || "150px"}
                                    position={regs.position || "left"}
                                    isActive={tab === regs.tab}
                                    disabled={regs.disabled}
                                    label={regs.label}
                                    icon={regs.icon}
                                    onClick={() => setTab(regs.tab)}
                                    bordercolor="rgb(10, 74, 135)"
                                >
                                    <div className="flex w-[100%] items-start justify-center gap-[40px] mt-[30px] mb-[50px]">
                                        <div className="w-[100%] m-0 p-0">
                                            {regs.content}
                                        </div>
                                    </div>
                                </TabPane>
                            ))}
                        </Tab> :
                        <div className="w-[92%] mx-auto mt-[60px]">
                            <div className="mb-4">
                                <img src={lockIcon} className="w-[15%]" />
                            </div>
                            <Margin top={40} />
                            <h6 className="text-[25px] not-italic font-normal leading-[28px]">Acesso restrito</h6>
                            <Margin top={15} />
                            <h5 className="text-[20px] not-italic font-normal leading-[28px]">Você não tem permissão para ver esse forum.</h5>
                        </div>
                    }

                </div>
                {isUserInvited &&
                    <div style={{ borderRadius: "10px" }}
                        className="bg-[#f4f4f4] w-[21%] h-[auto] m-0 p-[20px]">
                        <div>
                            <TextTitle>Status</TextTitle>
                            <Margin top={10} />
                            <div className="w-[80px] p-0 m-0">
                                {listForumFiltered && status_name[listForumFiltered?.forum_status]}
                            </div>
                            {listForumFiltered?.forum_status === "encerrado" && mostFrequentVote &&
                                <>
                                    <Margin top={10} />
                                    <div>
                                        <TextTitle>Resultado</TextTitle>
                                        <Margin top={5} />
                                        <TextSecondary>{mostFrequentVote && status_name[mostFrequentVote]}</TextSecondary>
                                    </div>
                                </>
                            }
                            <Margin top={20} />
                            <div>
                                <TextTitle>Solicitante</TextTitle>
                                <TextSecondary>{attendDetails?.client?.name}</TextSecondary>
                            </div>
                            <Margin top={30} />
                            <div>
                                <TextTitle>Tipo de fórum</TextTitle>
                                <TextSecondary>{listForumFiltered?.forum_type?.name}</TextSecondary>
                            </div>
                            <Margin top={30} />
                            <div>
                                <TextTitle>Data de abertura</TextTitle>
                                <TextSecondary>{listForumFiltered?.created_on ? formatarDataISO(String(listForumFiltered?.created_on || "0000-00-00T00:00:00.355981Z")) : ""}</TextSecondary>
                            </div>
                            <Margin top={30} />
                            <div>
                                <TextTitle>Data limite de votação</TextTitle>
                                <TextSecondary>{addHoursToDate(String(listForumFiltered?.vote_deadline), String(listForumFiltered?.created_on))}</TextSecondary>
                            </div>
                            {listForumFiltered?.forum_status === "encerrado" &&
                                <> <Margin top={30} />
                                    <div>
                                        <TextTitle>Quantidade de participantes</TextTitle>
                                        <TextSecondary>{participantList?.length}</TextSecondary>
                                    </div>
                                </>
                            }
                            <Margin top={30} />
                            <div>
                                <TextTitle>Votos realizados</TextTitle>
                                <Margin top={10} />
                                <div>
                                    {(listForumFiltered?.forum_status === "vetado" || listForumFiltered?.forum_status === "encerrado") && votes_reprover && votes_aprover ?
                                        <div className="flex flex-col">
                                            <div className="relative m-0 h-[5px] bg-[#c6c6c6] p-0">
                                                <div
                                                    className="absolute top-0 left-0 h-full bg-[#0e970e]"
                                                    style={{
                                                        width: `${totalParticipants > 0 ? (votes_aprover.length / totalParticipants) * 100 : 0}%`,
                                                    }}
                                                >
                                                </div>
                                                <div
                                                    className="absolute top-0 right-0 h-full bg-[#ff0000]"
                                                    style={{
                                                        width: `${totalParticipants > 0 ? (votes_reprover.length / totalParticipants) * 100 : 0}%`,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                            {/* <div className="bg-[#c6c6c6] h-[5px] mt-[10px] relative">
                                                        <div
                                                            className="bg-[#dcdcdc] h-full absolute top-0 left-0"
                                                            style={{
                                                                width: `${totalParticipants > 0 ? ((totalParticipants - (votes_aprover.length + votes_reprover.length)) / totalParticipants) * 100 : 0}%`,
                                                            }}
                                                        ></div>
                                                    </div> */
                                            }
                                            <div className="flex flex-col w-[100%] mx-auto justify-between gap-[1px] mt-[15px]">
                                                <p className="text-[14px] m-0">
                                                    A favor: {totalParticipants > 0 ? ((votes_aprover.length / totalParticipants) * 100).toFixed(0) : 0}%
                                                </p>
                                                <p className="text-[14px] m-0">
                                                    Contra: {totalParticipants > 0 ? ((votes_reprover.length / totalParticipants) * 100).toFixed(0) : 0}%
                                                </p>
                                                <p className="text-[14px] m-0">
                                                    Abstenção: {votes_annulled && totalParticipants > 0 ? ((votes_annulled.length / totalParticipants) * 100).toFixed(0) : 0}%
                                                </p>
                                                <p className="text-[14px] m-0">

                                                </p>
                                            </div>
                                        </div> :
                                        <div className="flex flex-col">
                                            <div className="bg-[#c6c6c6] h-[5px] mt-[10px] relative">
                                                <div
                                                    className="bg-[#0043CE] h-full absolute top-0 left-0"
                                                    style={{
                                                        width: `${totalParticipants > 0 ? (votesRealized / totalParticipants) * 100 : 0}%`,
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="flex w-[100%] mx-auto justify-between gap-[1px] mt-[15px]">
                                                <p className="text-[14px]">
                                                    Realizados: {totalParticipants > 0 ? ((votesRealized / totalParticipants) * 100).toFixed(0) : 0}%
                                                </p>
                                                <p className="text-[14px]">
                                                    Pendentes: {totalParticipants > 0 ? (100 - (votesRealized / totalParticipants) * 100).toFixed(0) : 100}%
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <Margin top={10} />
                            <div>
                                {listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado" ?
                                    <></>
                                    : <Button
                                        label={vote ? "Voto realizado" : "Votar"}
                                        kind="primary"
                                        size="medium"
                                        icon={checkIcon}
                                        onClick={() => !isUserInvited ? alert("Voce não foi convidado") : setModalVoteUser(true)}
                                        disabled={vote || !isUserInvited || usernoVote}
                                    />}
                            </div>
                            <Margin top={30} />
                            <div>
                                {(listForumFiltered?.forum_status === "vetado" || listForumFiltered?.forum_status === "encerrado")
                                    ? (
                                        <>
                                            <TextTitle>Votação encerrada</TextTitle>
                                            <TextSecondary>{formatarDataISO(String(listForumFiltered?.updated_on))}
                                            </TextSecondary>
                                        </>)
                                    : (
                                        <>
                                            <TextTitle>Votação encerra em:</TextTitle>
                                            <TextSecondary>{addHoursToDate(String(listForumFiltered?.vote_deadline),
                                                String(listForumFiltered?.created_on))}
                                            </TextSecondary>

                                        </>)}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ModalVoteUser
                forum={listForumFiltered}
                listRole={listFilteredParticipants}
                onClose={() => setModalVoteUser(false)}
                open={modalVoteUser}
            />
        </div>
    )
}
